.p-fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  margin: auto;
  z-index: -2;
}
