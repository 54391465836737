.c-heading-lg{
  word-break: break-word;
  font-size: 9rem;
  line-height: 1;
  @include mq-down(){
    font-size: 4.8rem;
    line-height: 0.9;
  }
  &.jp{
    font-family: $font-style;
  }
  &.en{
    font-family: $font-teko;
  }
}