.c-list-unlist{
  line-height: 1.8;
  margin-top: 28px;
  @include mq-down(){
    margin-top: 15px;
  }
  &__item{
    list-style-type: square;
    position: relative;
    line-height: 2.1;
    margin-left: 18px;
    @include mq-down(){
      font-size: 1.3rem;
      line-height: 1.55;
      padding-left: 11px;
      position: relative;
      list-style-type: none;
    }
    &::before{
      @include mq-down(){
        content: '';
        width: 6px;
        height: 6px;
        display: block;
        position: absolute;
        background: $color-black;
        left: -10px;
        top: 7px;
      }
    }
    span{
      display: inline-block;
      text-indent: -1em;
      padding-left: 1em;
    }
    + .c-list-unlist__item{
      &::before{
        @include mq-down(){
          content: '';
          width: 6px;
          height: 6px;
          display: block;
          position: absolute;
          background: $color-black;
          left: -10px;
          top: 7px;
        }
      }
    }
  }
  .c-list__item + .c-list__item{
    margin-top: 4px;
  }
}