
.c-list-footer-other-menu{
  padding: 0;
  @include mq-down(){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item{
    display: inline-block;
    @include mq-down(){
      width: 50%;
    }
    & + &{
      margin-left: 50px;
      @include mq-down(){
        margin-left: 0;
      }
    }
    &:nth-last-child(-n+2){
      @include mq-down(){
        margin-top: 16px;
      }
    }
  }
  &__link{
    display: inline-block;
    color: $color-white !important;
    font-size: 1.2rem;
    @include mq-down(){
      padding-top: 0 !important;
      font-size: 1rem;
    }
    &[target="_blank"]{
      &::after{
        content: '';
        background: url(/assets/_img/common/ico_link-2.svg) no-repeat !important;
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-left: 4px;
      }
    }
  }
}