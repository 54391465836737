.c-box-profile{
  &__in{
    padding: 0 5px;
    @include mq-down(){
      padding: 0;
    }
  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq-down(){
      justify-content: center;
    }
    .c-box-image{
      width: 23.076%;
      flex: 0 1 23.076%;
      @include mq-down(){
        width: 52.2%;
        flex: 0 1 52.2%;
        text-align: center;
      }
    }
    .c-box-detail{
      width: 74.038%;
      flex: 0 1 74.038%;
      padding-top: 30px;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
        padding-top: 21px;
      }
      .c-data-list{
        display: flex;
        flex-wrap: wrap;
        &__title{
          font-size: 1.5rem;
          line-height: 2;
          font-weight: 700;
          padding: 10px 10px 10px 0;
          width: 19%;
          flex: 0 1 19%;;
          border-bottom: 1px solid #DCDCDC;
          @include mq-down(){
            font-size: 1.3rem;
            line-height: 1.538;
            padding: 10px 10px 0 3px;
            border-bottom: 0;
            display: inline-block;
            width: 100%;
            flex: 0 1 100%;
          }
        }
        &__detail{
          width: 74.025%;
          flex: 0 1 74.025%;
          font-size: 1.5rem;
          line-height: 2;
          font-weight: 400;
          padding: 10px;
          border-bottom: 1px solid #DCDCDC;
          @include mq-down(){
            font-size: 1.3rem;
            line-height: 1.538;
            display: block;
            width: 100%;
            flex: 0 1 100%;
            padding: 4px 4px 8px;
          }
        }
      }
    }
    .c-label{
      font-size: 2.8rem;
      font-family: $font-teko;
      padding: 11px 10px;
      background: $color-black;
      color: $color-white;
      letter-spacing: 0.02em;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @include mq-down(){
        font-size: 2rem;
        line-height: 1.5;
        padding: 7px 10px 3px;
      }
      .c-link{
        font-size: 1.5rem;
        font-family: $font-style;
        color: $color-white;
        padding-right: 17px;
        position: relative;
        @include mq-up(){
          &:hover {
            opacity: 1;
            text-decoration: none;
          }
        }
        @include mq-down(){
          font-size: 1.3rem;
          line-height: 1.538;
          position: relative;
          top: -3px;
        }
        &::after{
          content: '';
          display: inline-block;
          width: 7px;
          height: 14px;
          background: url(/assets/_img/common/ico_arrow.svg) no-repeat;
          position: absolute;
          top: 1px;
          right: 0;
          background-size: 100%;
        }
      }
      .c-text-en{
        font-size: 2.8rem;
        font-family: $font-teko;
        @include mq-down(){
          font-size: 2rem;
          line-height: 1.5;
        }
      }
    }
  }
}