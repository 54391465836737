.c-box-full-width{
  width: calc(100% - 100px);
  margin: auto;
  @include mq-down(){
    width: 100%;
  }
  &.is-bg{
    position: relative;
    &::after{
      content: '';
      width: 100%;
      height: calc(100% - 80px);
      display: block;
      background: $color-blue;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  &.-full-bg{
    background: $color-blue;
  }
}