.l-footer {
  margin-top: auto;
  background: url(/assets/_img/common/bg-footer.jpg) no-repeat;
  background-size: cover;
  // padding-bottom: 68.90%;
  @include mq-down(){
    width: calc(100% - 30px);
    margin: 0 auto 15px;
  }
  @include mq-up(){
    width: calc(100% - 100px);
    margin: 130px auto 50px;
  }
  &__copyright {
    color: $color-white;
    text-align: center;
    font-size: 1rem;
    @include mq-up() {
      font-size: 1.2rem;
    }
  }
  &__in{
    color: #fff;
    position: relative;
    .c-footer-title{
      font-family: $font-teko;
      font-size: 5rem;
      text-align: center;
      line-height: 1.5;
      @include mq-down(){
        font-size: 4rem;
        line-height: 0.75‬;
      }
      .c-footer-jp-text{
        font-size: 2.8rem;
        font-family: $font-style;
        display: block;
        font-weight: 700;
        margin-top: -25px;
        @include mq-down(){
          font-size: 1.5rem;
          font-weight: normal;
          margin-top: -19px;
        }
      }
    }
    &.-bg-black--25{
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $color-black;
        opacity: 0.25;
        z-index: 1;
      }
    }
    &.-bg-black--50{
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $color-black;
        opacity: 0.5;
        z-index: 1;
      }
    }
  }
  &__top-box{
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    z-index: 2;
    @include mq-down(){
      padding-top: 50px;
      padding-bottom: 30px;
    }
  }
  &__bottom-box{
    padding-top: 88px;
    padding-bottom: 80px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 944px){
      padding: 58px 0 51px;
    }
  }
  &__bottom-sns{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 2px solid $color-white;
    margin-top: 30px;
    padding: 30px 9.62% 0 9.62%;
    position: relative;
    @include mq-down(){
      padding: 20px 2% 0 10.72%;
      border-top: 0;
    }
    &::before{
      @include mq-down(){
        position: absolute;
        top: 0;
        left: 10.72%;
        content: '';
        width: 100px;
        height: 2px;
        background: $color-white;
      }
    }
  }
  &__bottom-sns--logo{
    @include mq-down(){
      margin-top: 50px;
    }
    .c-footer-logo{
      display: block;

      &:hover {
        opacity: 1;
      }
    }
    .c-text-footer-logo{
      color: $color-white;
      display: block;
      font-size: 1.2rem;
      text-align: right;
      line-height: 1.5;
      margin-top: 10px;
      @include mq-down(){
        font-size: 1rem;
      }
    }
    .c-footer-logo--image{
      width: 223px;
      height: 52px;
      display: block;
      background-image: url('/assets/_img/common/footer_logo.svg');
      background-size: contain;
      @include mq-down(){
        width: 143px;
        height: 33px;
      }
    }
  }
  &__bottom-sns--link{
    @include mq-down(){
      width: 100%;
    }
  }
}
