.c-box-sns-top{
  &__in{
    margin-top: 130px;
    height: 450px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq-down(){
      margin-top: 85px;
      height: 605px;
    }
    .c-fb-box{
      width: 52%;
      @include mq-down(){
        width: 100%;
      }
      .fb_iframe_widget{
        @include mq-down(){
          width: 100%!important;
        }
      }
    }
    .c-sns-other-box{
      width: 45%;
      background: #DCDCDC;
      padding: 0 30px;
      @include mq-down(){
        width: 100%;
      }
      .fb-page{
        height: 100%;
      }
    }
  }
}