.c-box-course-row-type{
  &__in{
    padding: 30px 30px 40px;
    @include mq-down(){
      padding: 15px 15px 20px;
    }
  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & + &{
      margin-top: 50px;
    }
    .c-box-detail{
      width: 67.676%;
      flex: 0 1 67.676%;
      padding-right: 30px;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
        padding-right: 0;
      }
      .c-label{
        display: inline-block;
        font-size: 2.8rem;
        font-family: $font-teko;
        font-weight: 600;
        padding: 10px;
        line-height: 1.1;
        background: $color-black;
        color: $color-white;
        @include mq-down(){
          font-size: 2rem;
          line-height: 1.5;
          padding: 3px 10px 0;
        }
      }
      .c-title{
        font-size: 3.2rem;
        margin-top: 10px;
        line-height: 1.25;
        letter-spacing: -0.02em;
        font-weight: 700;
        @include mq-down(){
          font-size: 2rem;
          line-height: 1.5;
          margin-top: 6px;
        }
      }
      .c-sub-title{
        font-size: 2rem;
        margin-top: 10px;
        @include mq-down(){
          font-size: 1.3rem;
          line-height: 1.538;
          margin-top: 4px;
        }
      }
      .c-text{
        font-size: 1.5rem;
        line-height: 2;
        margin-top: 20px;
        @include mq-down(){
          font-size: 1.3rem;
        }
      }
    }
    .c-box-image{
      width: 32.324%;
      flex: 0 1 32.324%;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
        margin-top: 20px;
      }
      > img{
        @include mq-down(){
          width: 100%;
        }
      }
    }
    &.-is-bg{
      position: relative;
      padding-bottom: 50px;
      &::after{
        content: '';
        width: 1152px;
        height: calc(100% - 80px);
        position: absolute;
        left: 46.13%;
        bottom: 0;
        background: #EAF9FF;
        z-index: -2;
      }
      &.-left{
        &::after{
          right: 287px;
          left: unset;
          @include mq-down(){
            right: 64px;
          }
        }
      }
      &.-right{
        &::after{
          left: 287px;
          @include mq-down(){
            left: 64px;
          }
        }
      }
    }
  }
}