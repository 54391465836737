.c-campaign-top{
  position: relative;
  z-index: 2;
  &__title{
    text-align: center;
    .sm{
      font-size: 3.2rem;
      display: block;
      font-weight: 700;
      @include mq-down(){
        font-size: 2rem;
      }
    }
    .lg{
      font-size: 6.2rem;
      display: block;
      font-weight: 700;
      margin-top: 20px;
      @include mq-down(){
        font-size: 3.2rem;
        line-height: 1.1;
      }
      @media only screen and (min-width: 768px) and (max-width: 896px) {
        font-size: 5.2rem;
      }
    }
  }
  &__btn{
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    max-width: 640px;
    margin: auto;
    margin-top: 37px;
    padding: 2px 20px;
    background: #FA7777;
    border-radius: 110px;
    text-align: center;
    @include mq-down(){
      padding: 5px 20px;
      margin-top: 28px;
    }
    @-moz-document url-prefix(){
      padding: 0 20px 6px;
      top: -2px;
    }
    @supports (-ms-ime-align: auto){
      padding: 0 20px 6px;
      top: -2px;
    }
  }
  &__btn-in{
    display: flex;
    align-items: baseline;
    @-moz-document url-prefix(){
      margin-top: -4px;
    }
    @supports (-ms-ime-align: auto){
      margin-top: -4px;
    }
    > span{
      color: #fff;
      display: inline-block;
      &.text-01{
        font-size: 3.2rem;
        font-weight: 700;
        @include mq-down(){
          font-size: 2rem;
        }
      }
      &.text-02{
        font-size: 4rem;
        font-family: $font-teko;
        font-weight: 700;
        position: relative;
        top: 3px;
      }
      &.text-03{
        font-size: 2.4rem;
        font-weight: 700;
        @include mq-down(){
          font-size: 1.3rem;
        }
      }
      &.text-04{
        font-size: 6rem;
        font-family: $font-teko;
        font-weight: 700;
        position: relative;
        top: 3px;
        @include mq-down(){
          font-size: 4rem;
        }
      }
    }
  }
}