.c-box-achievement-list{
  &__in{
    width: 66.66%;
    margin: 0 auto;
    @include mq-down(){
      width: 100%;
    }
  }
  &__title{
    font-size: 2.8rem;
    padding: 10px 0;
    line-height: 1.428;
    position: relative;
    display: inline-block;
    @include mq-down(){
      font-size: 1.5rem;
      line-height: 1.5;
    }
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background: $color-black;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &__data-list{
    margin-top: 11px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq-down(){
      margin-top: 15px;
    }
    .c-title{
      display: inline-block;
      width: 20.129%;
      font-size: 1.5rem;
      line-height: 2;
      font-weight: 700;
      padding: 15px 20px 1px 0;
      border-bottom: 1px solid #DCDCDC;
      @include mq-down(){
        display: block;
        width: 100%;
        font-size: 1.3rem;
        line-height: 1.538;
        padding-left: 4px;
        border-bottom: 0;
        padding-top: 0;
      }
    }
    .c-detail{
      display: inline-block;
      width: calc(100% - 20.129%);
      font-size: 1.5rem;
      line-height: 2.1;
      padding: 15px 20px 1px 20px;
      border-bottom: 1px solid #DCDCDC;
      + .c-title{
        @include mq-down(){
          padding-top: 10px;
        }
      }
      @include mq-down(){
        display: block;
        width: 100%;
        padding-left: 0;
        font-size: 1.3rem;
        line-height: 1.538;
        padding: 0 0 11px 4px;
      }
    }
  }
}