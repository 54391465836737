.c-box-content-md{
  max-width: 900px;
  margin: 0 auto;
  @include mq-down(){
    padding: 0 15px;
    margin-top: 30px;
  }
  &__image{
    max-width: 755px;
    margin-left: 92px;
    margin-top: 50px;
    text-align: center;
    @include mq-down(){
      padding: 0;
      margin: 30px auto 0;
    }
    .c-box-text{
      text-align: left;
      margin-top: 30px;
      padding-left: 32%;
      letter-spacing: -0.02em;
      @include mq-down(){
        padding: 0;
        margin-top: 15px;
      }
      .c-text{
        max-width: 510px;
        display: inline-block;
        font-size: 1.5rem;
        line-height: 2;
        @include mq-down(){
          font-size: 1rem;
          line-height: 1.55;
        }
      }
    }
    .c-image{
      @include mq-down(){
        margin-top: 15px;
      }
    }
  }
  .c-text-common-jp{
    @include mq-down(){
      font-size: 1.3rem;
      line-height: 1.55;
    }
    + .c-text-common-jp{
      @include mq-down(){
        margin-top: 20px;
      }
    }
  }
}