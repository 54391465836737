.c-check {
  display: flex;
  align-items: baseline;
  &__text {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
  }
  // デフォルトのinput要素を非表示
  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
  // ラベルのフォーカススタイル
  input:focus + &__text::before {
    box-shadow: 0 0 4px #004aa1;
  }
  // チェックボックス
  [type="checkbox"] + &__text::before {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #999;
    background-color: #fff;
  }
  // チェックボックスのチェックスタイル
  [type="checkbox"] + &__text::after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: 5px;
    width: 7px;
    height: 14px;
    border-bottom: 3px solid #248b9a;
    border-right: 3px solid #248b9a;
    transform: rotate(40deg);
    opacity: 0;
    transition: opacity $transition;
  }
  [type="checkbox"]:checked + &__text::after {
    opacity: 1;
  }
  // ラジオボタン
  [type="radio"] + &__text::before {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #999;
    border-radius: 50%;
    background-color: #fff;
  }
  // ラジオボタンのチェックスタイル
  [type="radio"] + &__text::after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 3px;
    width: 9px;
    height: 9px;
    background: #248b9a;
    border-radius: 50%;
    opacity: 0;
    transition: opacity $transition;
  }
  [type="radio"]:checked + &__text::after {
    opacity: 1;
  }
  // エラー
  [type="checkbox"].is-invalid + &__text::before,
  [type="radio"].is-invalid + &__text::before {
    background-color: #ffe4e1;
    border-color: #e00100;
  }
  // 非活性
  [type="radio"][disabled] + &__text,
  [type="checkbox"][disabled] + &__text {
    opacity: .3;
    cursor: default;
  }
}
