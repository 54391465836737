.c-box-staff-index{
  &__in{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__card{
    width: 31.428%;
    flex: 0 1 31.428%;
    @include mq-down(){
      width: 100%;
      flex: 0 1 100%;
    }
    & + &{
      margin-top: 0;
      @include mq-down(){
        margin-top: 10px;
      }
    }
  }
  &__image{
    width: 100%;
    flex: 0 1 100%;
    @include mq-down(){
      width: 46.38%;
      flex: 0 1 46.38%;
    }
  }
  &__link{
    display: block;
    
    @include mq-down(){
      display: flex;
      flex-wrap: wrap;
    }
    &:hover{
      opacity: 1;
      .c-image { opacity: 0.7;}
      .c-title, .c-sub-title{
        color: $color-red;
      }
      .c-label{
        background: $color-red;
      }
    }
  }
  &__detail{
    background: $color-white;
    padding: 20px;
    width: 93.939%;
    margin-top: -78px;
    position: relative;
    z-index: 1;
    left: -1px;
    @include mq-down(){
      margin-top: 0;
      width: 53.599%;
      flex: 0 1 53.599%;
      padding: 20px 15px;
      left: 0;
    }
    .c-title{
      font-size: 2rem;
      line-height: 1.5;
      color: $color-black;
      transition: .3s;
      @include mq-down(){
        height: auto!important;
        font-size: 1.3rem;
        line-height: 1.95;
      }
    }
    .c-sub-title{
      font-size: 1.5rem;
      font-weight:  400;
      line-height: 2;
      margin-top: 16px;
      color: $color-black;
      transition: .3s;
      @include mq-down{
        font-size: 1.3rem;
        line-height: 1.538;
        margin-top: 10px;
      }
    }
    .c-label{
      font-size: 1.2rem;
      padding: 6px 10px;
      background: $color-black;
      color: $color-white;
      letter-spacing: -0.02em;
      transition: font-size .3s, line-height .3s, background-color .3s;
      transform: translateZ(0),rotate(0.01deg);
      backface-visibility: hidden;
      display: inline-block;
      line-height: 1.5;
      @include mq-down(){
        font-size: 1.3rem;
        line-height: 1.538;
        margin-top: 2px;
        padding: 4px 6px;
      }
    }
  }
}