.c-heading-md-line{
  font-size: 2.8rem;
  padding-bottom: 9px;
  border-bottom: 3px solid $color-black;
  display: inline-block;
  text-align: center;
  @include mq-down(){
    font-size: 1.5rem;
    line-height: 1.55;
    padding-bottom: 5px;
  }
}