.c-box-point-row-type{
  &__in{

  }
  &__card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 50px;
    @include mq-down(){
      padding-bottom: 0;
      margin-bottom: 40px;
    }
    & + & {
      margin-top: 30px;
      @include mq-down(){
        margin-top: 0;
      }
    }
    &.-left{
      position: relative;
      &::after{
        content: '';
        display: block;
        width: 1152px;
        height: calc(100% - 80px);
        position: absolute;
        bottom: 0;
        left: -385px;
        background: #EAF9FF;
        z-index: -1;
        @include mq-down(){
          bottom: 0;
          left: -15px;
          width: 85.7%;
          height: calc(100% - 40px);
        }
      }
      .c-box-detail{
        order: 2;
        @include mq-down(){
          padding-bottom: 40px;
        }
      }
      .c-box-image{
        order: 1;
      }
      &.-bg-right{
        &::after{
          content: '';
          display: block;
          width: 1152px;
          height: calc(100% - 80px);
          position: absolute;
          bottom: 0;
          left: 283px;
          background: #EAF9FF;
          z-index: -1;
          @include mq-down(){
            bottom: 0;
            left: 79px;
            width: 85.7%;
            height: calc(100% - 40px);
          }
        }
      }
    }
    .c-box-detail{
      width: 60.86%;
      flex: 0 1 60.86%;
      @include mq-down(){
        order: 2;
        width: 100%;
        flex: 0 1 100%;
      }
      &.-full-width{
        width: 100%;
        flex: 0 1 100%;
      }
      .c-label{
        display: inline-block;
        background: #000;
        font-size: 2.8rem;
        font-weight: 600;
        font-family: $font-teko;
        padding: 11px 11px 7px;
        color: #fff;
        position: relative;
        transition: .3s;
        @include mq-down(){
          margin-top: 10px;
          font-size: 2.4rem;
          padding: 8px 11px 6px;
        }
      }
      .c-title{
        margin-top: 13px;
        font-size: 3.2rem;
        line-height: 1.25;
        @include mq-down(){
          font-size: 2rem;
          line-height: 1.5;
          margin-top: 5px;
        }
      }
      .c-text{
        font-size: 1.5rem;
        margin-top: 30px;
        line-height: 2;
        @include mq-down(){
          margin-top: 10px;
          font-size: 1.3rem;
          line-height: 1.55;
        }

        &.-sm {
          font-size: 1.2rem;
          line-height: 1.5;
        }
      }
    }
    .c-box-image{
      width: 36.2%;
      flex: 0 1 36.2%;
      position: relative;
      @include mq-down(){
        order: 1;
        width: 100%;
        flex: 0 1 100%;
      }

      a { 
        display: block;
        width: 100%;
      }
      &.-is-video{
        position: relative;
        &::after {
          content: '';
          background: url(/assets/_img/common/ico_play.svg) no-repeat;
          background-size: 78px;
          width: 78px;
          height: 78px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          pointer-events: none;
        }
      }
    }
    .c-box-gray-sm{
      @include mq-down(){
        order: 3;
        width: 100%;
        flex: 0 1 100%;
      }
    }
    .c-box-text-cmn{
      @include mq-down(){
        order: 4;
        width: 100%;
        flex: 0 1 100%;
        margin-top: 10px;
      }
      .c-text-common-jp{
        @include mq-down(){
          font-size: 1.3rem;
          line-height: 1.55;
        }
      }
    }
    &.-play {
      .c-box-image {
        position: relative;
      }
    }
  }
  &.-reason{
    .c-label{
      background: #FA7777;
    }
  }
}
