.c-box-let-start{
  text-align: center;
  max-width: 1020px;
  margin: auto;
  @include mq-down(){
    padding: 0;
    margin-top: 28px;
  }
  .sub-title{
    font-size: 2.8rem;
    line-height: 1.55;
    font-weight: 700;
    @include mq-down(){
      font-size: 1.5rem;
      padding: 0 15px;
      line-height: 1.35;
    }
  }
}