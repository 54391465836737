
.list-woman{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  &__item{
    width: 19.049%;
    flex: 0 1 19.049%;
    display: flex;
    justify-content: center;
    background: #fff;
    margin-top: 10px;
    @include mq-down(){
      width: 48.4%;
      flex: 0 1 48.4%;
      align-items: center;
    }
    &.-small-sp{
      @include mq-down(){
        width: 48.4%!important;
        flex: 0 1 48.4%!important;
      }
    }
    .box-detail{
      .c-title{
        text-align: center;
        font-family: $font-teko;
        font-size: 2rem;
        line-height: 1.5;
        padding-top: 40px;
        @include mq-down(){
          padding-top: 0;
        }
      }
      .c-text{
        text-align: center;
        font-size: 1.5rem;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 700;
        line-height: 1.4;
        @include mq-down(){
          font-size: 1.3rem;
        }
      }
    }
    &.-big{
      width: 39.049%;
      flex: 0 1 39.049%;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
      }
    }
  }
}