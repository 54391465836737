.c-box-price-object{
  &__in{

  }
  &__card{
    width: 39.04%;
    @include mq-down(){
      width: 88.5%;
    }
    .c-image{

    }
    .c-title{
      background: $color-blue;
      padding: 6.35% 4.9%;
      font-size: 1.5rem;
      color: $color-black;
      position: relative;
      @include mq-up('lg'){
        padding: 6.35% 24% 6.35% 4.9%;
      }
      @include mq-down('lg'){
        padding: 6.35% 20% 6.35% 4.9%;
      }
      &::after{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: $color-black center center url(/assets/_img/common/ico_arrow.svg) no-repeat;
        background-size: 5px;
        border-radius: 50%;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s;
        @include mq-down(){
          right: 10px;
        }
      }
    }
    .c-link{
      text-decoration: none;
      display: block;
      &:hover{
        opacity: 1;
        .c-title{
          transition: 0.3s;
          color: $color-red;
          &::after{
            transition: 0.3s;
            background-color: $color-red;
          }
        }
      }
    }
  }
}