.c-navi {

}

.c-first-letter{
  color: $first-letter-en;
  display: inline-block;
}

.c-jp-text{
  display: block;
  font-family: $font-style;
  font-weight: normal;
  font-size: 1rem;
  width: 100%;
  line-height: 1.1;
  @include mq-up() {
    font-size: 1.2rem;
  }
}

.c-en-text{
  display: block;
  width: 100%;
  white-space: nowrap;
}