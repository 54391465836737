.c-box-best-row-type{
  &__in{
    .c-box-title{
      background: #1A1311;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      &__in{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;   
        width: calc(100% - 95px);
        @include mq-down(){
          width: 100%;
          padding-left: 0;
        }
      }
      &__label-red{
        font-size: 2.8rem;
        font-family: $font-teko;
        background: $color-red;
        color: #fff;
        display: inline-block;
        padding: 17px 10px 13px; 
        font-weight: 600;
        width: 95px;
        @include mq-down(){
          font-size: 2rem;
          line-height: 1.5;
          padding: 5px 13px 0px;
          width: auto;
          flex: 0 1 auto;
        }
      }
      &__label-black{
        font-size: 3.2rem;
        color: #fff;
        font-weight: 700;
        padding-left: 20px;
        @include mq-down(){
          font-size: 2rem;
          line-height: 1.5;
          padding: 10px 15px 10px;
        }
      }
      &__label-price{
        padding: 7px 20px 0 0;
        @include mq-down(){
          width: 100%;
          text-align: right;
          padding: 0 5px 9px 0;
        }
        span:nth-child(1){
          color: #fff;
          font-size: 5rem;
          font-family: $font-teko;
          @include mq-down(){
            font-size: 3.2rem;
          }
        }
        span:nth-child(2){
          color: #fff;
          font-size: 1.5rem;
          @include mq-down(){
            font-size: 1.3rem;
          }
        }
        span:nth-child(3){
          color: #fff;
          font-size: 2.8rem;
          font-family: $font-teko;
          @include mq-down(){
            font-size: 2.4rem;
            padding-right: 15px;
          }
        }
      }
    }
    .c-box-more{
      @include mq-down(){
        background: #fff;
        display: none;
        translate: 0.3s;
      }
      &.is-active{
        display: block;
      }
    }
    .c-box-detail{
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include mq-down(){
        border-left: 1px solid $color-black;
        border-right: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        margin-top: 0;
        padding: 15px 15px 20px;
      }
      &__in{
        width: 60.85%;
        flex: 0 1 60.85%;
        @include mq-down(){
          width: 100%;
          flex: 0 1 100%;
          order: 2;
        }
      }
      &__sub-title{
        font-size: 2.8rem;
        line-height: 1.07;
        margin-top: 9px;
        @include mq-down(){
          font-size: 1.5rem;
          line-height: 1.333;
          margin-top: 18px;
        }
      }
      &__text{
        font-size: 1.5rem;
        margin-top: 20px;
        line-height: 2;
        @include mq-down(){
          font-size: 1.3rem;
          line-height: 1.53;
          margin-top: 15px;
        }
      }
      &__image{
        width: 36.19%;
        flex: 0 1 36.19%;
        @include mq-down(){
          width: 100%;
          flex: 0 1 100%;
          order: 1;
        }
      }
    }
  }
}