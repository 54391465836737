.c-hero-lower {
  position: relative;

  figure {
    width: 100%;
    height: 700px;
    background-position: center center;
    background-repeat: none;
    background-size: cover;

    @include mq-down() {
      height: 375px;
    }
  }
}
