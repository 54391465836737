.c-list-campaign-step{
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 19px;
  @include mq-down(){
    display: block;
    margin-left: 8%;
    transform: scale(1.281);
    margin-bottom: 40px;
    margin-top: 18px;
  }
  @media only screen and (min-width: 768px) and (max-width: 896px) {
    margin-left: 11%;
  }
  &__item{
    width: 18.24%;
    flex: 0 1 18.24%;
    min-height: 151px;
    background: #F4F4F4;
    border-radius: 10px;
    @include mq-down(){
      width: 49%;
      // flex: 0 1 49%;
      margin-top: 10px;
      float: left;
      height: 114px;
      min-height: auto;
    }
    .c-title{
      width: calc(100% - 36px);
      margin: auto;
      background: $color-black;
      color: #fff;
      font-family: $font-teko;
      font-size: 2.8rem;
      font-weight: 700;
      text-align: center;
      padding: 3px 10px 1px;
      line-height: 1.333;
      align-self: center;
      @include mq-down(){
        width: calc(100% - 24px);
        font-size: 2rem;
        font-weight: normal;
      }
    }
    .c-detail{
      text-align: center;
      padding-top: 30px;
      line-height: 1.55;
      @include mq-down(){
        padding-top: 24px;
        line-height: 1.33;
      }
    }
  }
  .slick-slide{
    @include mq-down(){
      margin: 6px;
    }
  }
  .slick-list{
    overflow: visible;
  }
}