.c-heading-02 {
  margin-left: -$contents-gatter-sp;
  margin-right: -$contents-gatter-sp;
  padding: 0 10px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
  line-height: 1.4;
  font-size: 2rem;
  font-weight: bold;
  @include mq-up() {
    margin-left: 0;
    margin-right: 0;
    padding: 0 0 10px;
    font-size: 2.6rem;
  }
  // 余白
  & {
    margin-top: 10px;
    @include mq-up(){
      margin-top: 20px;
    }
  }
  & + [class] {
    margin-top: 10px;
    @include mq-up(){
      margin-top: 20px;
    }
  }
}
