.c-list-hero-footer{
  margin-top: 13px;
  &__item{
    text-indent: -1em;
    padding-left: 1em;
    @include mq-down(){
      font-size: 1rem;
      line-height: 1.33;
    }
    + .c-list-hero-footer__item{
      margin-top: 7px;
    }
  }
}