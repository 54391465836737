.p-sub-page{
  .p-welcome{
    margin-top: 45px;
    &__in{
      position: relative;
      padding-bottom: 80px;
      &::after{
        content: '';
        width: 1152px;
        height: calc(100% - 200px);
        bottom: 0;
        left: 26.96%;
        display: block;
        background: $color-blue;
        position: absolute;
        z-index: -1;
        @include mq-down(){
          content: '';
          width: 85.8%;
          height: calc(100% - 110px);
          right: -15px;
          bottom: 0;
          left: unset;
        }
      }
    }
    &__box-detail{
      padding-top: 10px;
      .c-sub-heading-lg{
        padding-top: 5px;
        @include mq-down(){
          text-align: center;
        }
      }
      .c-sub-heading-md.en{
        margin-top: 10px;
        @include mq-down(){
          text-align: center;
          margin-top: 0;
        }
      }
      .c-text{
        margin-top: 53px;
        @include mq-down(){
          margin-top: 19px;
          font-size: 1.3rem;
          line-height: 1.55;
        }
        &:not(:first-of-type){
          margin-top: 15px;
          @include mq-down(){
            margin-top: 20px;
          }
        }
      }
      .c-image{
        @include mq-down(){
          margin-top: 30px;
          position: relative;
          left: -15px;
        }
      }
      &::after{
        display: none;
        @include mq-down(){
          display: none;
        }
      }
      @include mq-down(){
        order: 2;
      }
    }
    &__box-image{
      @include mq-down(){
        order: 1;
      }
    }
    .p-content-bottom{
      @include mq-down(){
        order: 3;
      }
      .c-text{
        margin-top: 15px;
        @include mq-down(){
          margin-top: 19px;
          font-size: 1.3rem;
          line-height: 1.55;
        }
        &:not(:first-of-type){
          margin-top: 14px;
          @include mq-down(){
            margin-top: 20px;
          }
        }
      }
    }
  }
  .p-page-title__in{
    .c-heading-lg{
      &::after{
        @include mq-down(){
          height: 254.18%;
        }
      }
    }
    .c-heading-md{
      font-size: 2.8rem;
      @include mq-down(){
        font-size: 1.5rem;
      }
    }
    .c-text{
      margin-top: 53px;
      @include mq-down(){
        margin-top: 16px;
      }
    }
  }
  .p-contact-us{
    margin-top: 210px;
    @include mq-down(){
      margin-top: 0;
      margin-bottom: 38px;
    }
  }
  &.beginners {
    .p-contact-us{
      margin-top: 130px;
      @include mq-down(){
        margin-top: 0;
        margin-bottom: 38px;
      }
    }
  }
}