.c-heading-03 {
  line-height: 1.6;
  font-size: 1.8rem;
  font-weight: bold;
  // 余白
  & {
    margin-top: 10px;
    @include mq-up(){
      margin-top: 20px;
    }
  }
  & + [class] {
    margin-top: 5px;
    @include mq-up(){
      margin-top: 10px;
    }
  }

  &--lg {
    font-size: 4.6rem;
  }
}
