.p-page-title{
  margin-top: -100px;
  position: relative;
  z-index: 2;
  @include mq-down{
    margin-top: -80px;
  }
  &__in{
    margin: 0 auto;
    min-height: 350px;
    position: relative;
    @include mq-down{
      min-height: inherit;
      padding-right: 15px;
    }
    &::after{
      content: '';
      background: $color-blue;
      width: 1434px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      @include mq-down(){
        right: 15px;
      }
    }
    .c-heading-lg{
      padding-top: 69px;
      position: relative;
      padding-right: 100px;
      @include mq-down{
        font-size: 4.8rem;
        padding-top: 45px;
        padding-right: 15px;
      }

    }
    .c-heading-md{
      display: block;
      margin-top: -15px;
      @include mq-down{
        font-size: 1.5rem;
        margin-top: -5px;
      }
    }
    .c-text{
      margin-top: 50px;
      font-size: 2rem;
      line-height: 1.5;
      font-weight: 700;
      padding-right: 100px;
      @include mq-down{
        font-size: 1.3rem;
        line-height: 2;
        padding-right: 15px;
        margin-top: 15px;
        padding-bottom: 40px;
      }
    }
  }
}