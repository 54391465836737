.c-box-about-row-type{
  &__in{

  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & + &{
      margin-top: 50px;
      @include mq-down(){
        margin-top: 38px;
      }
    }
    &.-left{
      justify-content: flex-start;
      .c-box-detail{
        order: 2;
        margin-left: 30px;
        @include mq-down(){
          margin-left: 0;
        }
      }
      .c-box-image{
        order: 1;
      }
    }
    .c-box-detail{
      width: 52.38%;
      flex: 0 1 52.38%;
      padding-top: 8px;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
        order: 2;
        margin-top: 7px;
      }
      .c-title{
        font-size: 3.2rem;
        padding-bottom: 15px;
        display: inline-block;
        position: relative;
        line-height: 1.15;
        @include mq-down(){
          font-size: 2rem;
          line-height: 1.5;
          padding-bottom: 9px;
        }
        &::after{
          content: '';
          width: 100%;
          height: 3px;
          background: $color-black;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      .c-list{
        margin-top: 28px;
        @include mq-down(){
          margin-top: 15px;
        }
        &__item{
          list-style-type: square;
          padding-left: 20px;
          position: relative;
          @include mq-down(){
            font-size: 1.3rem;
            line-height: 1.538;
            padding-left: 11px;
          }
          &::before{
            content: '';
            width: 7px;
            height: 7px;
            display: block;
            position: absolute;
            top: 6px;
            left: 3px;
            transform: translateX(-50%);
            background: $color-black;
          }
        }
        .c-list__item + .c-list__item{
          margin-top: 4px;
        }
      }
    }
    .c-box-image{
      width: 36.19%;
      flex: 0 1 36.19%;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
        order: 1;
      }
    }
  }
}