
.c-list-footer-sns{
  padding: 17px 0 0;
  @include mq-down(){
    padding: 22px 0 0;
  }
  &__item{
    display: inline-block;
    & + &{
      margin-left: 30px;
    }
  }
  &__link{
    &:after {
      display: none !important;
    }
  }
}