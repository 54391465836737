.c-box-reason-campaign{
  background: #EAF9FF url(/assets/_img/campaign/bg_blue.svg);
  padding-top: 100px;
  padding-bottom: 130px;
  width: calc(100% - 100px);
  margin: auto;
  margin-top: -50px;
  @include mq-down(){
    width: 100%;
    padding-top: 48px;
    padding-bottom: 52px;
  }
  .c-box-content-grid-type{
    padding-bottom: 0;
    &.-no-bg{
      &::before{
        display: none;
      }
    }
    .c-title{
      text-align: center;
      @include mq-down(){
        margin-top: 17px;
      }
    }
  }
  .c-box-content-grid-type__card .c-label{
    max-width: 250px;
    margin: -25px auto 0;
    text-align: center;
    @include mq-down(){
      max-width: 200px;
      margin: -31px auto 0;
    }
  }
}