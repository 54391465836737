.p-sns{
  width: calc(100% - 100px);
  margin: 0 auto;
  @include mq-down(){
    width: calc(100% - 30px);
  }
  &__title{
    &::before{
      content: '';
      display: block;
      width: 58px;
      height: 58px;
    }
  }
  &__content{
    margin-top: 34px;
    overflow: hidden;
    @include mq-down(){
      overflow: visible;
    }
    .list-sns{
      display: block;
      max-height: 260px;
      overflow: hidden;
      @include mq-down(){
        max-height: 200px;
        width: calc((160px * 7) + (10px * 6));
      }
      &__item{
        width: 260px!important;
        height: 260px!important;
        background: #DCDCDC;
        float: left;
        @include mq-down(){
          width: 160px!important;
          height: 160px!important;
        }
      }
      &__link{
        width: 100%;
        height: 100%;
        display: block;
      }
      .slick-list{
        transform: scale(0.86);
        overflow: visible;
        margin-left: -8%;
      }
      .slick-slide{
        margin: 0 10px;
      }
    }
  }
  .c-box-sns-top{
    @include mq-down(){
      padding: 0;
    }
  }
}