.c-list-benefits{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  @include mq-down(){
    margin-top: 11px;
  }
  &__item{
    width: 48.58%;
    flex: 0 1 48.58%;
    background: #fff;
    border-radius: 10px;
    @include mq-down(){
      width: 100%;
      flex: 0 1 100%;
      margin-top: 20px;
    }
    &:nth-child(n+3){
      @include mq-up(){
        margin-top: 30px;
      }
    }
    &.-box-01{
      .c-card{
        .c-title{
          &::before{
            right: -51px;
            top: -13px;
            @include mq-down(){
              right: -21px;
              top: -6px;
            }
          }
        }
      }
    }
    &.-box-02{
      .c-card{
        .c-title{
          .text-sm{
            @include mq-down(){
              position: relative;
              left: -15px;
            }
          }
          &::before{
            right: -47px;
            top: -13px;
            @include mq-down(){
              right: -22px;
              top: -3px;
            }
          }
        }
      }
    }
    &.-box-03{
      .c-card{
        .c-title{
          &::before{
            @include mq-up(){
              top: 14px;
              right: 26px;
            }
            @include mq-down(){
              right: -24px;
              top: 23px;
            }
          }
        }
      }
    }
    &.-box-04{
      .c-card{
        .c-title{
          &::before{
            @include mq-up(){
              top: 48px;
              right: 72px;
            }
            @include mq-down(){
              top: 47px;
              right: 13px;
            }
          }
        }
      }
    }

    .c-card{
      padding: 40px;
      padding-top: 0;
      text-align: center;
      @include mq-down(){
        padding: 0 23px 20px;
      }
      .c-label{
        margin: auto;
        background: $color-black;
        padding: 10px;
        color: #fff;
        text-align: center;
        font-size: 2rem;
        @include mq-down(){
          padding: 8px 10px 6px;
        }
        .text-en{
          font-family: $font-teko;
          font-size: 3.3rem;
          position: relative;
          top: 2px;
          margin-left: 10px;
          font-weight: 700;
          @include mq-down(){
            font-size: 2.4rem;
          }
        }
      }
      .c-title{
        margin-top: 43px;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 20px;
        position: relative;
        @include mq-down(){
          width: 83.63%;
          margin-top: 20px;
          line-height: 1.5;
          padding-bottom: 11px;
        }
        &::after{
          content: '';
          width: 274px;
          height: 3px;
          background: $color-black;
          display: block;
          position: absolute;
          bottom: 0;
          @include mq-down(){
            width: 100%;
            left: 0;
          }
        }
        &::before{
          content: '';
          width: 40px;
          height: 30px;
          background: url(/assets/_img/campaign/accent05.svg) no-repeat;
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          @include mq-down(){
            width: 33px;
            height: 25px;
            background-size: contain;
          }
        }
        .text-sm{
          font-size: 2.8rem;
          font-weight: 700;
          width: 168px;
          text-align: left;
          color: $color-black;
          @include mq-down(){
            font-size: 2rem;
            width: auto;
            display: inline-block;
          }
          &.-full-width{
            display: block;
            width: 100%;
          }
        }
        .text-lg{
          font-size: 4.6rem;
          font-weight: 700;
          color: #FA7777;
          margin-left: 15px;
          align-self: center;
          @include mq-down(){
            font-size: 4rem;
          }
        }
      }
      .c-detail{
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: 1.55;
        text-align: center;
        @include mq-down(){
          font-size: 1.3rem;
          margin-top: 15px;
          text-align: left;
        }
      }
    }
  }
}
.c-text-footer-sp{
  text-align: center;
  text-indent: -1em;
  padding-left: 1em;
  @include mq-down(){
    font-size: 1rem!important;
    text-align: left;
    margin-top: 11px;
    line-height: 1.33!important;
  }
}