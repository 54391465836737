$padding-min: 0;
$padding-max: 200;
$padding-multiple: 5;

//max-width
@include responsive-down(".u-p-0") {
  padding: 0 !important;
}
@include responsive-down(".u-pb-0") {
  padding-bottom: 0 !important;
}
@include responsive-down(".u-pl-0") {
  padding-left: 0 !important;
}
@include responsive-down(".u-pr-0") {
  padding-right: 0 !important;
}

@for $i from $padding-min through $padding-max / $padding-multiple {
  @include responsive-down(".u-pt-#{$i * $padding-multiple}") {
    padding-top: #{$i * $padding-multiple}px !important;
  }
  @include responsive-down(".u-pb-#{$i * $padding-multiple}") {
    padding-bottom: #{$i * $padding-multiple}px !important;
  }
  @include responsive-down(".u-pl-#{$i * $padding-multiple}") {
    padding-left: #{$i * $padding-multiple}px !important;
  }
}

//min-width
@include responsive-up(".u-p-0") {
  padding: 0 !important;
}
@include responsive-up(".u-pb-0") {
  padding-bottom: 0 !important;
}
@include responsive-up(".u-pl-0") {
  padding-left: 0 !important;
}
@include responsive-up(".u-pr-0") {
  padding-right: 0 !important;
}

@for $i from $padding-min through $padding-max / $padding-multiple {
  @include responsive-up(".u-pt-#{$i * $padding-multiple}") {
    padding-top: #{$i * $padding-multiple}px !important;
  }
}

@for $i from $padding-min through $padding-max / $padding-multiple {
  @include responsive-up(".u-pb-#{$i * $padding-multiple}") {
    padding-bottom: #{$i * $padding-multiple}px !important;
  }
}

@for $i from $padding-min through $padding-max / $padding-multiple {
  @include responsive-up(".u-pl-#{$i * $padding-multiple}") {
    padding-left: #{$i * $padding-multiple}px !important;
  }
}
