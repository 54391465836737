.c-box-benefits-step{
  position: relative;
  background: #FFEAEA url(/assets/_img/campaign/bg_pink.svg);
  padding-bottom: 130px;
  width: calc(100% - 100px);
  margin: auto;
  z-index: 0;
  @include mq-down(){
    width: 100%;
    padding-bottom: 50px;
  }
  .c-box-benefits-step__in{
    @include mq-up(){
      &::before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        right: 0;
        width: 32.295vw;
        height: 20%;
        background: url(/assets/_img/campaign/accent04.png) no-repeat center bottom;
        background-size: 32.295vw auto;
        z-index: 1;
      }
    }
  }
  .c-box-step-row-type__in{
    background: #fff;
    padding-left: 30px;
    z-index: 2;
    @include mq-down(){
      padding-left: 0;
      padding: 0;
    }
    &::after{
      display: none;
    }
    .c-box-step-row-type__image{
      width: 49%;
      flex: 0 1 49%;
      position: absolute;
      top: -30px;
      right: 30px;
      @include mq-down(){
        width: 100%;
        display: block;
        flex: 0 1 100%;
        padding: 0 15px;
        position: relative;
        top: auto;
        right: auto;
        
      }
    }
    .c-box-step-row-type__card{
      position: relative;
      margin-top: 0;
    }
    .c-box-step-row-type__detail{
      width: 100%;
      flex: 0 1 100%;
      .c-title{
        width: 45.15%;
        padding-left: 15px;
        line-height: 1.4;
        margin-top: 7px;
        @include mq-down(){
          width: 100%;
          flex: 0 1 100%;
        }
      }
      .c-text{
        width: 45.15%;
        padding-left: 15px;
        padding-bottom: 110px;
        margin-top: 20px;
        @include mq-down(){
          width: 100%;
          flex: 0 1 100%;
          padding-bottom: 15px;
          margin-top: 9px;
          line-height: 1.55;
          padding-right: 15px;
        }
        &.-height-auto{
          height: auto!important;
        }
      }
    }
    .c-box-step-row-type__detail .c-label{
      display: block;
      padding: 14px 10px 7px;
      @include mq-down(){
        margin-top: 0;
        font-size: 2rem;
        padding: 9px 14px 6px;
        width: calc(100% - 45px);
      }
    }
  }
  .c-box-step-row-type__in{
    padding-top: 59px;
    @include mq-down(){
      padding-top: 6px;
    }
  }
}