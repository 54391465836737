.p-tab {
  &__navi {
    display: flex;
    margin-bottom: -1px;
  }
  &__item {
    display: flex;
    flex: 1;
  }
  &__item + &__item {
    padding-left: 10px;
  }
  &__link {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 10px;
    border: 1px solid #248b9a;
    border-bottom: none;
    text-align: center;
    background-color: #248b9a;
    color: #fff;
  }
  &__body {
    padding: 10px;
    border: 1px solid #248b9a;
    background-color: #fff;
  }
  &__block {
    display: none;
  }
  //アクティブ
  &__link:hover,
  &__link.is-active {
    background-color: #fff;
    color: #248b9a;
    opacity: 1;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background-color: #fff;
    }
  }
  &__block.is-active {
    display: block;
  }
  // 余白
  * + & {
    margin-top: 10px;
    @include mq-up(){
      margin-top: 20px;
    }
  }
}
