.c-text {
  line-height: 1.8;
  // 余白
  * + & {
    margin-top: 10px;
    @include mq-up(){
      margin-top: 20px;
    }
  }

  &.-sm {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.c-text-red{
  color: $color-red;
}
.c-text-pink{
  color: $color-pink;
}
.c-text-green{
  color: $color-green;
}
.c-text-common-jp{
  font-size: 1.5rem;
  line-height: 2;
  font-family: $font-style;
  &.-sm{
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.c-text-common-jp-large{
  font-size: 2.8rem;
  line-height: 1.428;
  font-family: $font-style;
}
.c-text-common-en{
  font-size: 1.5rem;
  line-height: 2;
  font-family: $font-teko;
}
.c-text-common-en-large{
  font-size: 2.8rem;
  line-height: 1.071;
  font-family: $font-teko;
}
.c-text-subtitle {
  display: block;
  font-size: 2.8rem;
  font-family: "Teko", sans-serif;
  margin-top: 15px;

  @include mq-down() {
    font-size: 2rem;
    margin-top: 10px;
  }
}