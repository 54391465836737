.l-main {
  overflow: hidden;
  &.p-parts{
    margin-top: 220px;
    @include mq-down(){
      margin-top: 130px;
    }
  }
}
.l-hero{
  text-align: center;
  &__in{
    position: relative;
    img{
      @include mq-down(){
        width: 100%;
      }
    }
  }
}
.l-content{
  min-height: 400px;
}
.l-inner{
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

  @include mq-down(){
    padding-left: 15px;
    padding-right: 15px;
  }
}
