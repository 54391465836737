.c-embed {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
  margin: 0;
  padding: 0;
  padding-bottom: percentage(9 / 16);
  &__item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0;
  }
  // 余白
  * + & {
    margin-top: 10px;
    @include mq-up(){
      margin-top: 20px;
    }
  }
}

.c-embed-map {
  iframe { 
    width: 100%;
    @include mq-down(){
      height: 400px !important;
    }
  }
}