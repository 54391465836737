.c-box-question-small{
  &__in{
    width: 88.24%;
    margin: 0 auto;
    @include mq-down(){
      width: 100%;
    }
  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & + &{
      padding-bottom: 70px;
      @include mq-down(){
        padding-bottom: 40px;
        margin-top: 80px;
      }
    }
    .c-box-detail{
      width: 70%;
      flex: 0 1 70%;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
      }
      .c-sub-title{
        @extend .c-text-common-en-large;
        @include mq-down(){
          font-size: 2rem!important;
          line-height: 1.2 !important;
        }
      }
      .c-title{
        @extend .c-text-common-jp-large;
        display: inline-block;
        position: relative;
        padding-bottom: 13px;
        @include mq-down(){
          font-size: 1.5rem!important;
          line-height: 1.333!important;
          padding-bottom: 11px;
        }
        + .c-image{
          @include mq-down(){
            margin-top: 20px;
            margin-bottom: 10px;
            width: 104.3%;
            left: -15px;
            position: relative;
          }
        }
        &::after{
          content: '';
          width: 100%;
          height: 3px;
          background: $color-black;
          position: absolute;
          left: 0;
          bottom: 0;
          @include mq-down(){
            height: 2px;
          }
        }
      }
      .c-text{
        @extend .c-text-common-jp;
        margin-top: 25px;
        @include mq-down(){
          font-size: 1.3rem!important;
          line-height: 1.538!important;
          margin-top: 0;
        }
        p{
          @include mq-down(){
            line-height: 1.6;
          }
        }
        p + p{
          margin-top: 13px;
          @include mq-down(){
            margin-top: 30px;
          }
        }
      }
    }
    .c-box-image{
      width: 26.666%;
      flex: 0 1 26.666%;
      position: relative;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
      }
      .c-image{
        width: 672px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        overflow: hidden;
        @include mq-down(){
          width: 100%;
          position: relative;
          margin-top: 20px;
        }
        img{
          width: auto;
          max-width: 672px;
          @include mq-down(){
            max-width: 100%;
          }
        }
      }
    }

    &.-full-width{
      .c-box-detail{
        width: 100%;
        flex: 0 1 100%;
      }
    }
    &.-right-bg{
      position: relative;
      + .c-box-question-small__item.-left-bg{
        margin-top: 80px;
        @include mq-down(){
          margin-top: 81px;
        }
      }
      &::after{
        content: '';
        width: 128%;
        height: calc(100% - 80px);
        position: absolute;
        bottom: 0;
        left: 23%;
        background: #EAF9FF;
        z-index: -1;
        @include mq-down(){
          width: 85.7%;
          height: 93%;
          left: unset;
          right: -15px;
          top: 45px;
        }
      }
      .c-box-detail{
        padding-top: 40px;
        @include mq-down(){
          padding-top: 0;
        }
      }
    }
    &.-full-width{
      + .c-box-question-small__item.-left-bg{
        margin-top: 80px;;
      }
      + .c-box-question-small__item.-right-bg{
        margin-top: 80px;;
      }
    }
    &.-left-bg{
      position: relative;
      + .c-box-question-small__item.-right-bg{
        margin-top: 80px;
        @include mq-down(){
          margin-top: 85px;
        }
      }
      .c-box-detail{
        order: 2;
        padding-top: 40px;
        @include mq-down(){
          padding-top: 0;
          order: 1;
        }
        .c-title{
          @include mq-down(){
            padding-bottom: 13px;
          }
          + .c-image{
            @include mq-down(){
              left: 0;
            }
          }
        }
      }
      .c-box-image{
        @include mq-down(){
          order: 2;
        } 
      }
      .c-image{
        order: 1;
        position: absolute;
        width: 672px;
        right: 0;
        top: unset;
        left: unset;
        @include mq-down(){
          width: 100%;
          order : 2;
          position: relative;
        }
        img{
          @include mq-down(){
            max-width: 100%;
          }
        }
      }
      &::after{
        content: '';
        width: 128%;
        height: calc(100% - 80px);
        position: absolute;
        bottom: 0;
        right: 23%;
        background: #EAF9FF;
        z-index: -1;
        @include mq-down(){
          width: 85.7%;
          height: 92.9%;
          top: 46px;
          right: 0;
          left: -15px;
        }
      }
    }
  }
}