.p-pagetop {
  position: absolute;
  right: 0;
  top: -125px;
  opacity: 0;
  transition: opacity $transition;
  width: 110px;
  height: 45px;
  background: url(/assets/_img/common/img_gotop@2x.jpg) no-repeat;
  background-size: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: .3s;
  opacity: 1;
  &:hover{
    opacity: 0.7;
  }
  &__trigger {
    color: #fff;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;
    font-family: $font-teko;
    margin-top: 15px;
    &::before { 
      min-width: 30px;
      min-height: 30px;
      margin-right: 0;
      display: none;
    }
  }
  &.is-active {
    opacity: 1;
  }
}
.c-box-pagetop{
  position: relative;
}
