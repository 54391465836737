.c-box-wave {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 31;
    width: 100%;
    height: 100vh;
    background-color: #fff;
  }

  &__item {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.wave-01 {
      z-index: 30;
  
      &.is-animated {
        animation: openning 1.2s ease 0.9s;
        animation-fill-mode: both;
      }
    }
  
    &.wave-02 {
      z-index: 29;
  
      &.is-animated {
        animation: openning 1.5s ease 0.9s;
        animation-fill-mode: both;
      }
    }
  }

  &__bg {
    flex: 0 0 auto;
    width: 100%;
    height: 100vh;
  }

  &__item--blue &__bg {
    background-color: #EAF9FF;
  }

  &__item--pink &__bg {
    background-color: #FFEAEA;
  }

  &__image {
    flex: 0 0 auto;
    width: 100%;
    padding-top: 14.573%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__item--blue &__image {
      background-image: url(/assets/_img/common/bg_wave_01.svg);
  }

  &__item--pink &__image {
      background-image: url(/assets/_img/common/bg_wave_02.svg);
  }
}

@keyframes openning {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
