.c-box-step-row-type{
  &__in{
    padding: 50px 0;
    position: relative;
    @include mq-down(){
      padding: 20px 0 40px;
    }
    &::after{
      content: '';
      width: 23.61%;
      height: 100%;
      display: block;
      position: absolute;
      left: 6.28%;
      bottom: 0;
      background: $color-blue;
      z-index: -1;
      @include mq-down(){
        width: 34.7%;
        left: 10.28%;
        height: calc(100% - 20px);
      }
    }
  }
  &__card{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & + &{
      margin-top: 80px;
      @include mq-down(){
        margin-top: 40px;
      }
    }
  }
  &__detail{
    width: 60.85%;
    flex: 0 1 60.85%;
    @include mq-down(){
      width: 100%;
      flex: 0 1 100%;
      order: 2;
    }
    .c-label{
      display: inline-block;
      font-size: 2.8rem;
      font-family: $font-teko;
      font-weight: 600;
      padding: 10px 10px 7px;
      line-height: 1.1;
      background: $color-black;
      color: $color-white;
      @include mq-down(){
        font-size: 2.4rem;
        line-height: 1.25;
        padding: 5px 10px 2px;
        margin-top: 10px;
      }
    }
    .c-title{
      font-size: 3.2rem;
      margin-top: 18px;
      @include mq-down(){
        font-size: 2rem;
        line-height: 1.5;
        margin-top: 5px;
      }
      span{
        font-size: 2.8rem;
        @include mq-down(){
          font-size: 1.5rem;
          line-height: 1.333;
          padding-left: 10px;
        }
      }
    }
    .c-text{
      margin-top: 34px;
      line-height: 2;
      font-size: 1.5rem;
      @include mq-down(){
        font-size: 1.3rem;
        line-height: 1.53;
        margin-top: 10px;
      }
    }
  }
  &__image{
    width: 36.19%;
    flex: 0 1 36.19%; 
    @include mq-down(){
      width: 100%;
      flex: 0 1 100%;
      order: 1;
    }
    img{
      @include mq-down(){
        width: 100%;
      }
    }
  } 
}