.c-sub-heading-lg{
  font-size: 4.6rem;
  font-weight: 700;
  line-height: 1.086;
  @include mq-down{
    font-size: 2.6rem;
    line-height: 1.384;
  }
  &.jp{
    font-family: $font-style;
  }
  &.en{
    font-family: $font-teko;
  }
}
.c-sub-heading-md{
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.0714;
  margin-top: 20px;
  @include mq-down{
    font-size: 2rem;
    line-height: 1.5;
  }
  &.jp{
    font-family: $font-style;
  }
  &.en{
    font-family: $font-teko;
  }
}

