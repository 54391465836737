.c-button {
  $this: #{&};
  display: inline-flex;
  width: 100%;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 1.2;
  justify-content: center;
  align-items: center;
  text-align: left;
  border: 1px solid #248b9a;
  background-color: #248b9a;
  letter-spacing: normal;
  color: #fff;
  @include mq-up(){
    margin-left: 10px;
    margin-right: 10px;
  }
  &__text {
    display: inline-flex;
  }
  // size
  &-auto {
    @extend #{$this};
    width: auto;
  }
  &-small {
    @extend #{$this};
    max-width: 100px;
  }
  &-middle {
    @extend #{$this};
    max-width: 200px;
  }
  &-large {
    @extend #{$this};
    max-width: 300px;
  }
  // colors
  &--strong {
    border-color: #e00100;
    background-color: #e00100;
  }
  &--weak {
    border-color: #999;
    background-color: #999;
  }
  // 非活性
  &[disabled] {
    @extend #{$this};
    opacity: .3;
  }
  // group
  &-group {
    display: flex;
    justify-content: center;
    margin-left: -5px;
    margin-right: -5px;
    @include mq-up(){
      margin-left: -10px;
      margin-right: -10px;
    }
  }
  // 余白
  * + &-group {
    margin-top: 10px;
    @include mq-up(){
      margin-top: 20px;
    }
  }
}
.l-header{
  &__menu-sidebar-fix{

  }
  &__menu-item{

    &.c-button-school{
      border: 0!important;
      border-radius: 15px 0 0 0;
      .c-title.-tel-icon{
        font-size: 1.2rem;
        display: block;
        color: #1A1311!important;
        text-align: left;
        padding-left: 25px;
        line-height: 1.3;
        background: url(/assets/_img/common/ico_phone-1.svg) left 0 no-repeat!important;
        font-weight: 700;
        font-family: $font-style;
        border-bottom: none;
      }
      .c-tel{
        display: block;
        text-align: left;
        font-size: 2.8rem;
        line-height: 0.85;
        padding-left: 25px;
        margin-top: 10px;
      }
      .c-verticle-text{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 20px;
        border: 1px solid #1A1311;
        padding: 4px 2px;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1;
      }
      .c-text-detail{
        display: block;
        width: calc(100% - 21px);
        font-size: 1.2rem;
        text-align: left;
        padding-left: 10px;
        line-height: 1.3;
      }
      .c-time, .c-text{
        display: flex;
        flex-wrap: wrap;
        font-family: $font-style;
      }
      .c-time{
        margin: 10px 0;
        
        @include mq-up() {
          .c-verticle-text {
            height: 71px;
          }
        }
      }
      .c-text{
        @include mq-up() {
          .c-verticle-text {
            height: 56px;
          }
        }
      }
      .l-header__menu-link{
        transition: 0.3s;
        color: #1A1311;
        opacity: 1;
        &:hover{
          opacity: 0.7;
        }
      }
    }
  }
}