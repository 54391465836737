div.p-welcome{
  margin-top: 80px;
  @include mq-down(){
    margin-top: 60px;
  }
  &__in{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__box-detail{
    width: 57.81%;
    flex: 0 1 57.81%;
    padding-top: 68px;
    position: relative;
    @include mq-down(){
      width: 100%;
      flex: 0 1 100%;
      padding-top: 0;
    }
    &::after{
      content: '';
      width: 189.8%;
      height: 88.35%;
      background: $color-blue;
      display: block;
      top: 200px;
      left: 283px;
      position: absolute;
      z-index: -1;
    }
    .c-text{
      margin-top: 43px;
      @include mq-down(){
        margin-top: 30px;
        font-size: 1.3rem;
        line-height: 1.55;
      }
    }
    .c-heading-lg{
      @include mq-down(){
        font-size: 4.8rem;
        text-align: center;
        line-height: 0.9;
      }
    }
    .c-heading-md{
      @include mq-down(){
        font-size: 1.5rem;
        text-align: center;
        margin-top: -2px;
      }
    }
    .-half-width{
      width: 50%;
    }
  }
  &__box-image{
    width: 37.43%;
    flex: auto;
    padding-left: 50px;
    position: relative;

    a { 
      display: block;
      @include mq-up(){
        &:hover {
          > .c-image {
            img { opacity: 0.7;}
          }
        }
      }
    }
    @include mq-down(){
      width: 104.3%;
      flex: 0 1 104.3%;
      margin-left: -15px;
      padding-left: 0;
    }
    .c-image{
      width: 40vw;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      padding:0 0 10px 30px;
      @include mq-down(){
        padding: 0;
        margin-top: 30px;
        width: 100%;
        position: relative;
      }
      img{
        @include mq-up(){ 
          transition: opacity 0.3s;
        }
        @include mq-down(){
          width: 100%;
        }
      }
    }
  }
  &__box-banner{
    width: 100%;
    flex: 0 1 100%;
    margin-top: 80px;
    @include mq-down(){
      margin-top: 30px;
      padding: 0 10px;
    }
    .c-list-banner{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      &__item{
        width: 49.05%;
        flex: 0 1 49.05%;
        box-shadow: 0 0 10px rgba(113, 113, 113, 0.3);
        @include mq-down(){
          width: 100%;
          flex: 0 1 100%;
        }
        &:not(:first-child){
          margin-left: 1.9%;
          @include mq-down(){
            margin-left: 0;
            margin-top: 20px;
          }
        }
        img {
          @include mq-down(){
            width: 100%;
          }
        }
      }
    }
  }
  
  &.-play {
    .c-image {
      // position: relative;
      &::after {
        content: '';
        background: url(/assets/_img/common/ico_play.svg) no-repeat;
        background-size: 78px;
        width: 78px;
        height: 78px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }
  }
}
