.c-hero {
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include mq-up() {
    height: 300px;
  }
  &__text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 50px;
    opacity: 0;
    transition: opacity 1s ease-in;
    transition-delay: 0.4s;
    @include mq-down(){
      margin-top: 20px;
    }

    &.on {
      opacity: 1;
    }
    img{
      filter: drop-shadow(0px 0px 15px #000);
      @include mq-down(){
        filter: drop-shadow(0px 0px 4px #000);
      }
    }
    // &::after{
    //   @include mq-down(){
    //     content: '';
    //     display: block;
    //     width: 188px;
    //     height: 78px;
    //     background: url('/assets/_img/common/main_ttl.svg') no-repeat;
    //     background-size: cover;
    //   }
    // }
  }
  &__text--small{
    font-size: 7rem;
    color: #fff;
  }
  &__text--large{
    font-size: 8rem;
    color: #fff;
  }
}
