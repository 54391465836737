.c-box-voice-grid-type{
  &__in{
    width: 88.24%;
    margin: 0 auto;
    @include mq-down(){
      width: 100%;
    }
  }
  &__item{
    & + &{
      margin-top: 80px;
      @include mq-down(){
        margin-top: 43px;
      }
    }
    &.-left-bg{
      position: relative;
      padding-bottom: 80px;
      @include mq-down(){
        padding-bottom: 40px;
      }
      &::after{
        content: '';
        width: 128%;
        height: calc(100% - 80px);
        position: absolute;
        bottom: 0;
        right: 23%;
        background: $color-blue;
        z-index: -1;
        @include mq-down(){
          bottom: 0;
          right: -15px;
          width: 85.8%;
          height: calc(100% - 45px);
        }
      }
    }
    &.-right-bg{
      position: relative;
      padding-bottom: 80px;
      @include mq-down(){
        padding-bottom: 40px;
      }
      &::after{
        content: '';
        width: 128%;
        height: calc(100% - 80px);
        position: absolute;
        bottom: 0;
        left: 23%;
        background: $color-blue;
        z-index: -1;
        @include mq-down(){
          bottom: 0;
          right: -15px;
          width: 85.8%;
          height: calc(100% - 45px);
          left: unset;
        }
      }
    }
    .c-box-image{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .c-image{
        width: 48.895%;
      }
    }
    .c-box-detail{
      .c-label{
        display: inline-block;
        font-size: 2.8rem;
        font-family: $font-teko;
        font-weight: 600;
        padding: 10px 8px;
        line-height: 1.1;
        background: $color-black;
        color: $color-white;
        margin-top: 20px;
        @include mq-down(){
          font-size: 2.4rem;
          line-height: 1.25;
          padding: 7px 8px 3px;
          margin-top: 10px;
        }
      }
      .c-title{
        margin-top: 13px;
        font-size: 3.2rem;
        line-height: 1.25;
        @include mq-down(){
          font-size: 2rem;
          line-height: 1.5;
          margin-top: 5px;
        }
      }
      .c-sub-title{
        font-size: 1.5rem;
        font-weight: 400;
        margin-top: 10px;
        line-height: 2;
        @include mq-down(){
          font-size: 1.3rem;
          line-height: 1.538;
          margin-top: 4px;
        }
      }
      .c-text{
        font-size: 1.5rem;
        line-height: 2;
        margin-top: 23px;
        @include mq-down(){
          font-size: 1.3rem;
          line-height: 1.538;
          margin-top: 20px;
        }
      }
    }
  }
}