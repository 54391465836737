.c-box-woman{
  width: calc(100% - 100px);
  margin: 0 auto;
  background: $color-blue;
  @include mq-down(){
    width: 100%;
  }
  &__in{
    
  }
}