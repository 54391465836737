.l-section {
  $this: #{&};
  margin-top: 40px;
  margin-bottom: 40px;
  @include mq-up() {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  &--fill {
    @extend #{$this};
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #dbdad6;
    @include mq-up() {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
  & > :first-child {
    margin-top: 0;
  }
}

.l-section-part{
  + .l-section-part{
    margin-top: 50px;
    overflow: hidden;
  }
}
