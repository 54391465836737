.c-list-banner-common {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 130px;

  @include mq-down() {
    flex-direction: column;
    padding: 0 15px;
    margin-top: 50px;
  }

  > * {
    width: 49.05%;
    box-shadow: 0 0 10px rgba(113, 113, 113, 0.3);

    img {
      width: 100%;
    }

    @include mq-down() {
      width: 100%;
    }
  }
}
