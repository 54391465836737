.c-link {
  text-decoration: underline;
  color: #248b9a;
}
a:not([href$=".pdf"]):not(.no-icon)[target="_blank"]{
  position: relative;
  color: $color-black;
  padding-top: 25px;
  display: inline-block;
  &::after{
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url(/assets/_img/common/ico_link-0.svg) no-repeat;
    position: relative;
    top: 1px;
    right: -5px;
    @include mq-down(){
      background: url(/assets/_img/common/ico_link-0-sp.svg) no-repeat;
      color: $color-red;
    }
  }
  &:hover{
    color: $color-red;
    &::after{
      background: url(/assets/_img/common/ico_link-1.svg) no-repeat;
      @include mq-down(){
        background: url(/assets/_img/common/ico_link-0-sp.svg) no-repeat;
      }
    }
  }
}

.c-link-tel {
  @include mq-up() {
    pointer-events: none;
    cursor: default;
  }

  &.black {
    color: $color-black;
  }
}

.c-link-line {
  text-decoration: underline;
  color: $color-black;

  @include mq-up() {
    &:hover {
      text-decoration: none;
      opacity: 1;
    }
  }
}