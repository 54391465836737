.c-box-achievement{
  &__in{

  }
  &__title{
    position: relative;
    &::before{
      content: '';
      width: 100%;
      height: calc(100% - 60px);
      display: block;
      background: $color-blue;
      bottom: 0;
      left:0;
      position: absolute;
      z-index: -1;
      @include mq-down(){
        bottom: unset;
        left: 0;
        top: 106px;
      }
    }
    .c-sub-title{
      font-family: $font-teko;
      font-size: 2.8rem;
      line-height: 1.071;
      text-align: center;
      margin-top: 16px;
      @include mq-down(){
        font-size: 2rem;
        line-height: 1.5;
        margin-top: 3px;
      }

      &.-sm {
        font-size: 2.4rem;

        @include mq-down(){
          font-size: 2rem;
        }
      }
    }
  }
  &__title-in{
    text-align: center;
    .c-title{
      font-size: 4.6rem;
      line-height: 1.086;
      @include mq-down(){
        font-size: 2.6rem;
        line-height: 1.384;
      }

    }
  }
  &__item{
    padding-top: 56px;
    padding-bottom: 60px;
    @include mq-down(){
      padding-top: 18px;
      padding-bottom: 40px;
    }
    &:last-of-type{
      padding-bottom: 0;
    }
    * + .c-box-button-small__link{
      margin-top: 20px;
    }
    &.-bg-blue{
      background: $color-blue;
      + .c-box-achievement__item{
        margin-top: 23px;
      }
      &.-space{
        @include mq-up(){
          background: none;
          position: relative;
          overflow: hidden;
          padding-top: 0;
          padding-bottom: 40px;
        }
        &::after{
          @include mq-up(){
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 80px;
            z-index: -1;
            background: $color-blue;
          }

        }
      }
      &.-half{
        background: none;
        position: relative;
        padding-top: 0;
        margin-top: 20px;
        padding-bottom: 80px;
        @include mq-down(){
          margin-top: 0;
          padding-bottom: 40px;
        }
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: calc(100% - 130px);
          position: absolute;
          bottom: 0;
          background: $color-blue;
          z-index: -1;
          @include mq-down(){
            height: calc(100% - 50px);
          }
        }
      }
    }
  }
  &__item-in{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .c-box-detail{
      width: 48.571%;
      flex: 0 1 48.571%;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
        margin-top: 5px;
      }
      .c-text{
        @include mq-down(){
          font-size: 1.3rem;
          margin-top: 13px;
          line-height: 1.6;
        }
      }
      .c-box-button-small__link{
        margin-top: 20px;
        @include mq-down(){
          padding: 14px 16px;
        }
      }
    }
    .c-box-image{
      width: 48.571%;
      flex: 0 1 48.571%;
      @include mq-down(){
        width: 100%;
        flex: 0 1 100%;
      }
    }
    .c-box-title{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .c-title{
        width: 100%;
        line-height: 1.25;
        font-size: 3.2rem;
        display: flex;
        flex-wrap: wrap;

        @include mq-down() {
          font-size: 2rem;
          line-height: 1.5;
        }

        .c-title-in {
          flex: 1 0 0;
          padding-left: 10px;
        }

        .c-text-small {
          display: block;
          line-height: 1.25;
          font-size: 2.4rem;
          margin-top: 10px;
          width: 100%;

          @include mq-down() {
            margin-top: 12px;
            font-size: 1.5rem;
            line-height: 1.333;
          }
        }

        .c-text-large {
          display: block;
          padding-top: 4px;
        }
      }
    }

    .c-box-label {
      flex: 0 0 auto;
      font-family: $font-teko;
      font-weight: 700;
      padding: 12px 9px 9px;
      font-size: 2.8rem;
      line-height: 1.071;
      color: $color-white;
      background: $color-black;
      display: inline-block;
      max-height: 50px;

      @include mq-down(){
        font-size: 2.4rem;
        line-height: 1.25;
        padding: 5px 8px 5px;
        height: 40px;
      }
    }
    &.-right{
      .c-box-detail{
        order: 1;
        @include mq-down(){
          margin-top: 10px;
          order: 2;
        }
      }
      .c-box-image{
        order: 2;
        @include mq-down(){
          order: 1;
        }
      }
    }
  }
}