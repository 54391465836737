.c-box-qa{
  &__in{

  }
  &__item{
    border: 5px solid $color-blue;
    overflow: hidden;
    width: 85.714%;
    margin: auto;
    background: #fff;
    @include mq-down(){
      width: 100%;
    }
    & + &{
      margin-top: 20px;
      @include mq-down(){
        margin-top: 10px;
      }
    }
    .c-question{
      padding: 25px;
      font-size: 2rem;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include mq-down(){
        font-size: 1.3rem;
        line-height: 1.53;
        padding: 13px 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      .c-qa-text{
        @include mq-down(){
          width: calc(100% - 93px);
        }
      }
      .c-icon-qa{
        display: block;
        width: 40px;
        height: 40px;
        display: block;
        position: absolute;
        background: $color-blue;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        @include mq-down(){
          width: 35px;
          height: 35px;
          position: relative;
          transform: translateY(0);
          top: auto;
          right: -13px;
          align-self: flex-start;
        }
        &::before{
          content: '';
          position: absolute;
          background: $color-black;
          width: 21px;
          height: 1px;
          left: 50%;
          top: 50%;
          margin-left: -10px;
          transition: transform 0.3s ease;
        }
        &::after{
          content: '';
          position: absolute;
          background: $color-black;
          width: 21px;
          height: 1px;
          left: 25%;
          top: 50%;
          transition: transform 0.3s ease, opacity 0.3s ease;
          transform: rotate(90deg);
          @include mq-down(){
            left: 24%;
          }
        }
      }
      &.is-active{
        position: relative;
        .c-icon-qa{
          &::after{
            transform: rotate(0deg);
            opacity: 0;
          }
        }
        &::after{
          content: '';
          width: calc(100% - 60px);
          height: 2px;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          background: #F4F4F4;
          display: block;
          position: absolute;
          @include mq-down(){
            width: calc(100% - 20px);
            height: 2px;
            left: 50%;
            bottom: -4px;
          }
        }
      }
      .c-icon-q{
        font-size: 2.8rem;
        font-family: $font-teko;
        background: $color-black;
        color: $color-white;
        padding: 0 10px;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 5px;
        @include mq-down(){
          width: 35px;
          height: 35px;
          font-size: 2rem;
          line-height: 1.5;
          padding: 3px 11px;
          align-self: baseline;
        }
      }
    }
    .c-answer{
      display: none;
      &.is-active{
        display: block;
      }
      &__in{
        padding: 11px 30px 20px;
        @include mq-down(){
          padding: 11px 11px;
        }
        p{
          font-size: 1.5rem;
          line-height: 2;
          @include mq-down(){
            font-size: 1.3rem;
            line-height: 1.6;
          }
        }
      }
      * + *{
        margin-top: 30px;
        @include mq-down(){
          margin-top: 10px;
        }
      }
      .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px; height: 0; overflow: hidden;
      }
        
      .video-container iframe,
      .video-container object,
      .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}