.p-breadcrumb {
  display: block;
  overflow-x: scroll;
  font-size: 1rem;
  @include mq-up() {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    color: $color-black;
    font-size: 1.2rem;
    overflow-x: hidden;
  }
  @include mq-down() {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  &__group {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    @include mq-down(){
      width: auto;
      white-space:nowrap; 
      flex-wrap: nowrap;
      margin-top: 10px;
      display: inline-block;
    }
  }
  &__item{
    font-size: 0;
    line-height: 1.5;
    @include mq-down(){
      display: inline-block;
    }
  }
  &__item:last-child{
    @include mq-up() {
      color: $color-black;
      font-size: 1.2rem;
    }
    @include mq-down(){
      padding-right: 15px;
      font-size: 1rem;
    }
  }
  &__item + &__item::before {
    content: '/';
    margin: 0 5px;
    @include mq-up() {
      color: $color-black;
      font-size: 1.2rem;
    }
    @include mq-down() {
      font-size: 1rem;
    }
  }
  &__link {
    color: $color-black;
    text-decoration: underline;
    @include mq-up() {
      color: $color-black;
      font-size: 1.2rem;
    }
    @include mq-down() {
      font-size: 1rem;
    }
    &:hover{
      text-decoration: none;
    }
  }
}
