.l-block {
  padding-left: $contents-gatter-sp;
  padding-right: $contents-gatter-sp;
  @include mq-up() {
    max-width: 100vw;
    padding-left: $contents-gatter-pc;
    padding-right: $contents-gatter-pc;
  }
  @media screen and (min-width: #{$contents-width + $contents-gatter-pc * 2}) {
    padding-left: calc((100% - #{$contents-width}) / 2);
    padding-right: calc((100% - #{$contents-width}) / 2);
  }
}

.l-block--part{
  //width: calc(100% - 100px);
  margin: 0 auto;
  @include mq-down(){
    //width: calc(100% - 30px);
  }
  &:last-child(){
    margin-bottom: 50px;
  }
}