.c-box-more-btn{
  border-left: 1px solid #1A1311;
  border-right: 1px solid #1A1311;
  border-bottom: 1px solid #1A1311;
  margin-top: 0;
  padding: 15px 15px 10px;
  display: none;
  background: #fff;
  &.js-more-02{
    border-top: 1px solid #1A1311;
  }
  &.js-more-03{
    border-top: 1px solid #1A1311;
  }
  &.js-more-04{
    border-top: 1px solid #1A1311;
  }
  &.js-more-05{
    border-top: 1px solid #1A1311;
  }
  @include mq-down(){
    display: block;
    margin-top: 5px;
  }
  &__link{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $color-black;
    font-weight: 700;
    &::after{
      content: '';
      position: relative;
      display: inline-block;
      width: 7px;
      height: 14px;
      background: url(/assets/_img/common/ico_arrow-black.svg) no-repeat;
      background-size: 100%;
      transform: rotate(90deg);
      margin-left: 10px;
      transition: 0.3s;
    }
    span{
      @include mq-down(){
        position: relative;
        top: -2px;
      }
    }
  }
  &.is-active{
    .c-box-more-btn__link{
      &::after{
        transform: rotate(270deg);
      }
    }
  }
}