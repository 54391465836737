
.c-box-contact-us{
  width: calc(100% - 100px);
  margin: 0 auto;
  border: 30px solid #FFEBEB;
  position: relative;
  z-index: 2;
  @include mq-down(){
    width: calc(100% - 20px);
    border: 10px solid #FFEBEB;
  }
  &__in{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #fff;
    @include mq-down(){
      padding-bottom: 30px;
    }
    &.l-block{
      @include mq-down(){
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &__button{
    width: 50%;
    padding-top: 50px;
    padding-left: 1.905%;
    @include mq-down(){
      padding: 0 20px;
      width: 100%;
      margin-top: 18px;
    }
    .c-button-contact{
      width: 100%;
      display: block;
      padding: 20px;
      text-align: center;
      border: 2px solid #DA003E;
      border-radius: 60px;
      @include mq-down(){
        min-height: 60px;
      }
      &:hover{
        border: 2px solid #BE093C;
      }
      &__in{
        padding-left: 65px;
        display: inline-block;
        text-align: left;
        > span{
          color: #1A1311;
          display: block;
          font-size: 2.4rem;
          font-weight: 700;
          @include mq-up(){
            padding-left: 17px;
          }
          @include mq-down(){
            font-size: 1.3rem;
            text-align: center;
          }
          &.c-tel-no{
            font-family: $font-teko;
            font-size: 5rem;
            margin-top: -10px;
            @include mq-up(){
              margin-top: -5px;
            }
          }
        }
      }
    }
    .c-button-contact--tel{
      @extend .c-button-contact;
      padding: 11px 20px;
      background: #fafafa;
      @include mq-down(){
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
      }
      .c-button-contact__in{
        background-image: url('/assets/_img/common/ico_phone-0.svg');
        background-repeat: no-repeat;
        background-position: 0px 15px;
        @include mq-up(){
          background-position: 17px 19px;
        }
        @include mq-down(){
          background-size: 30px;
          width: auto;
          display: block;
          padding: 16px 30px 16px 56px;
          background-position: 15px 12px;
        }
      }
      &:hover{
        opacity: 1;
        .c-button-contact__in{
          background-image: url('/assets/_img/common/ico_phone-0-on.svg');
        }
      }
      &:after {
        display: none;
        content: url('/assets/_img/common/ico_phone-0-on.svg');
      }
    }
    .c-button-contact--email{
      @extend .c-button-contact;
      background: #DA003E;
      margin-top: 18px;
      transition: .3s;
      transform: translateZ(0);
      backface-visibility: hidden;
      @include mq-down(){
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      }
      &:hover{
        background: #BE093C;
        opacity: 1;
        .c-button-contact__in{
          &:before{
            z-index: -1;
            opacity: 0;
          }
        }
      }
      .c-button-contact__in{
        //background-image: url('/assets/_img/common/ico_mail.svg');
        //background-repeat: no-repeat;
        //@debugbackground-position: 0px 15px;
        position: relative;
        @include mq-down(){
          background-size: 30px;
          display: block;
          width: auto;
          background-position: 15px 8px;
          padding: 0 30px 0 56px;
        }
        &:before{
          content: '';
          width: 45px;
          height: 45px;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -23px;
          background: url('/assets/_img/common/ico_mail.svg') no-repeat center center;
          z-index: 2;

          @include mq-down(){
            left: 15px;
            margin-top: -15px;
            width: 30px;
            height: 30px;
            background-size: 30px;
          }
        }
        &:after{
          content: '';
          width: 45px;
          height: 45px;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -23px;
          background: url('/assets/_img/common/ico_mail-on.svg') no-repeat center center;
          z-index: 1;

          @include mq-down(){
            left: 15px;
            margin-top: -15px;
            width: 30px;
            height: 30px;
            background-size: 30px;
          }
        }
      }
      .c-text-email{
        line-height: 1.5;
        padding: 15px 0;
        color: $color-white;
      }
    }
  }
  &__detail{
    width: 50%;
    padding: 0 1.905% 0 9.048%; 
    @include mq-down(){
      padding: 0 20px;
      width: 100%;
    }
    .c-title{
      font-size: 9rem;
      font-weight: 700;
      font-family: $font-teko;
      padding-bottom: 20px;
      position: relative;
      @media screen and (max-width: 944px){
        font-size: 8rem;
      }
      @include mq-down(){
        font-size: 4.8rem;
        text-align: center;
        padding-bottom: 6px;
      }
      &::after{
        content: '';
        width: 100px;
        border-bottom: 2px solid #1A1311;
        position: absolute;
        left: 0;
        bottom: 0;
        @include mq-down(){
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .c-first-letter{
        color: $color-red;
      }
      .c-text-jp{
        font-size: 2.8rem;
        display: block;
        line-height: 1.4;
        @include mq-down(){
          font-size: 1.5rem;
        }
      }
    }
    .c-sub-title{
      font-size: 2rem;
      margin-top: 30px;
      @include mq-down(){
        font-size: 1.3rem;
        margin-top: 10px;
      }
    }
    .c-text{
      font-size: 1.5rem;
      margin-top: 17px;
      line-height: 1.4;
      @include mq-down(){
        font-size: 1.3rem;
        margin-top: 5px;
      }
    }
  }
  &__pink, &__pink-no-bdr{
    @extend .c-box-contact-us;
    border: 20px solid #F8CACA;
    @include mq-down(){
      border: 10px solid #F8CACA;
      padding: 10px;
    }
    .c-box-contact-us{
      &__in{
        padding-top: 60px;
        padding-bottom: 53px;
        @include mq-down(){
          padding-top: 32px;
          padding-bottom: 18px;
        }
      }
      &__detail{
        padding: 0;
        width: 100%;
        flex: 0 1 100%;
        text-align: center;
        .c-title{
          padding-bottom: 11px;
          @include mq-down(){
            padding-bottom: 6px;
          }
          &::after{
            left: 50%;
            transform: translateX(-50%);
          }
          .c-text-jp{
            @include mq-down(){
              line-height: 1.35;
            }
          }
        }
      }
      &__button{
        padding: 0;
        width: 100%;
        flex: 0 1 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        padding: 0 15px;
        @include mq-down(){
          margin-top: 19px;
          padding: 0 10px;
        }
        .c-button-contact--tel{
          width: 49%;
          border: 2px solid $color-pink;
          transition: 0.3s;
          @include mq-down(){
            width: 100%;
          }
          .c-button-contact__in{
            background-image: url('/assets/_img/common/ico_phone-pink.svg');
            background-position: 16px 15px;
            @include mq-down(){
              background-position: 6px 15px;
              width: 70%;
            }
            .c-text{
              padding-left: 10px;
              @include mq-down(){
                padding-left: 14px;
              }
            }
            .c-tel-no{
              padding-left: 10px;
            }
          }
          &:hover{
            border: 2px solid #EA5B5B;
            .c-button-contact__in{
              background-image: url('/assets/_img/common/ico_phone-pink-on.svg');
            }
          }
          &:after {
            display: none;
            content: url('/assets/_img/common/ico_phone-pink-on.svg');
          }
        }
        .c-button-contact--email{
          width: 49%;
          margin-top: 0;
          border: 2px solid $color-pink;
          background: $color-pink;
          transition: 0.3s;
          transform: translateZ(0);
          backface-visibility: hidden;
          @include mq-down(){
            width: 100%;
            margin-top: 10px;
          }
          .c-button-contact__in{
            position: relative;
            background-position: 0 10px;
            &:before{
              content: '';
              width: 45px;
              height: 45px;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -23px;
              background: url(/assets/_img/common/ico_mail-pink.svg) no-repeat center center;
              z-index: 2;

              @include mq-down(){
                left: 6px;
                margin-top: -15px;
                width: 30px;
                height: 30px;
                background-size: 30px;
              }
            }
            &:after{
              content: '';
              width: 45px;
              height: 45px;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -23px;
              background: url(/assets/_img/common/ico_mail-pink-on.svg) no-repeat center center;
              z-index: 1;

              @include mq-down(){
                left: 6px;
                margin-top: -15px;
                width: 30px;
                height: 30px;
                background-size: 30px;
              }
            }
            @include mq-down(){
              background-position: 6px 9px;
              width: 70%;
            }
          }
          .c-text-email{
            @include mq-down(){
              padding-left: 14px;
            }
          }
          &:hover{
            background-color: #EA5B5B;
            .c-button-contact__in{
              &:before{
                z-index: -1;
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
} 

