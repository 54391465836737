.c-box-gray-sm{
  width: 85.72%;;
  flex: 0 1 85.72%;
  margin: 30px auto 0;
  @include mq-down(){
    width: 100%;
    flex: 0 1 100%;
    padding: 0;
    margin: 10px auto 0;
  }
  &.-box-text{
    width: 92.19%;
    @include mq-down(){
      width: calc(100% - 30px);
      margin: 20px auto 0;
    }
    .c-box-gray-sm__in{
      padding: 23px 30px 32px;
      @include mq-down(){
        padding: 23px 15px 32px;
      }
    }
  }
  &__in{
    padding: 30px;
    background: #F4F4F4;
    @include mq-down(){
      padding: 15px 15px 6px;
    }
    .c-datalist-conversation{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      &__title{
        width: 87px;
        flex: 0 1 87px;
        margin-bottom: 16px;
        @include mq-down(){
          width: 72px;
          flex: 0 1 72px;
          margin-bottom: 0;
        }
      }
      &__detail{
        width: calc(100% - 87px);
        flex: 0 1 calc(100% - 87px);
        line-height: 1.3;
        @include mq-down(){
          width: calc(100% - 72px);
          flex: 0 1 calc(100% - 72px);
          margin-bottom: 13px;
        }
      }
    }
  }
  &__list-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 25px;
    @include mq-down(){
      margin-top: 10px;
    }
  }
  &__list{
    width: 50%;
    @include mq-down(){
      width: 100%;
    }
    &:nth-child(1){
      padding-left: 50px;
      padding-right: 50px;
      @include mq-down(){
        padding: 0;
      }
    }
    &:nth-child(2){
      padding-left: 50px;
      padding-right: 50px;
      @include mq-down(){
        padding: 0;
      }
    }
  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 17px;
    @include mq-down(){
      margin-bottom: 10px;
    }
    span{
      font-size: 1.5rem;
      display: block;
      @include mq-down(){
        font-size: 1.3rem;
        line-height: 1.55;
      }
      &:nth-child(1){
        width: 80%;
      }
      &:nth-child(2){
        width: 20%;
        text-align: right;
      }
    }
  }
  &__text-sm{
    font-size: 1.2rem;
    margin-top: 22px;
    padding: 0 32px;
    @include mq-down(){
      padding: 0 15px;
      text-indent: -1em;
      font-size: 1rem;
      line-height: 1.55;
    }
  }
  &__title{
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    @include mq-down(){
      font-size: 1.3rem;
    }
  }
}

.c-box-gray-md{
  &__in{
    max-width: 900px;
    margin: 0 auto;
    background: #F4F4F4;
    padding: 20px 50px;
    @include mq-down(){
      padding: 15px;
    }
    .c-title{
      text-align: center;
      font-size: 2rem;
    }
  }
}