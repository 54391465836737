.c-box-button-small{
  width: calc(100% - 100px);
  margin: 0 auto;
  @include mq-down(){
    text-align: center;
  }
  &.-full-width{
    width: 100%;
  }
  &__link{
    border: 1px solid #1A1311;
    border-radius: 110px;
    display: inline-block;
    padding: 18px 68px;
    line-height: 1.38;
    transition: 0.3s;
    background: #fff;
    position: relative;
    @include mq-down(){
      padding: 14px 20px;
      width: calc(100% - 45px);
      line-height: 1.45;
    }

    &.-sm {
      padding-left: 60px;
      padding-right: 60px;
      @include mq-down(){
        padding: 14px 0;
        width: calc(100% - 45px);
        line-height: 1.45;
      }
    }
    &:hover, &.is-active{
      border: 1px solid $color-red;
      opacity: 1;
      .c-box-button-small{
        &__text{
          color: $color-red;
        }
      }
      .c-box-button-small__text {
        background: url(/assets/_img/common/ico_arrow-on.svg) no-repeat center right;
      }
    }
    & + & {
      @include mq-down(){
        margin-top: 20px;
      }
    }
  }
  &__text{
    color: #1A1311;
    font-size: 1.5rem;
    font-weight: 700;
    transition: 0.3s;
    padding-right: 17px;
    background: url(/assets/_img/common/ico_arrow-1.svg) no-repeat center right;
    transform: translateZ(0);
    backface-visibility: hidden;
    @include mq-down(){
      font-size: 1.3rem;
      text-align: left;
      display: inline-block;
    }
  }
  &.-full-width{
    @include mq-down(){
      width: 100%;
    }
  }
}