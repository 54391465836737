.c-box-content-grid-type{
  position: relative;
  padding-bottom: 152px;
  width: calc(100% - 100px);
  margin: 0 auto;
  @include mq-down(){
    padding-bottom: 80px;
    width: 100%;
  }
  &::before{
    content: '';
    display: block;
    position: absolute;
    background: #EAF9FF;
    width: 100%;
    height: calc(100% - 80px);
    bottom: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    @include mq-down(){
      height: calc(100% - 110px);
    }
  }
  &__in{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }
  &__card{
    width: 48.571%;
    flex: 0 1 48.571%;
    margin-top: 33px;
    @include mq-down(){
      width: 100%;
      flex: 0 1 100%;
    }
    & + &{
      @include mq-down(){
        margin-top: 20px;
      }
    }
    &:nth-child(1), &:nth-child(2){
      @include mq-up(){
        margin-top: 0;
      }
    }
    .c-label{
      width: 77.8%;
      background: #000;
      font-size: 2.8rem;
      font-weight: 600;
      font-family: $font-teko;
      padding: 11px 30px;
      color: #fff;
      margin-top: -25px;
      position: relative;
      margin-left: -30px;
      margin-right: -30px;
      transition: .3s;
      @include mq-down(){
        width: 88.8%;
        font-size: 2rem;
        line-height: 1.5;
        padding: 5px 15px;
        margin-left: -15px;
        margin-top: -30px;
      }
    }
    .c-title{
      line-height: 1.2;
      font-size: 2.8rem;
      font-weight: bold;
      color: #1A1311;
      margin-top: 15px;
      transition: .3s;
      @include mq-down(){
        font-size: 2rem;
        line-height: 1.5;
        margin-top: 5px;
      }
    }
    .c-box-detail{
      background: #fff;
      padding: 0 30px 23px;
      @include mq-down(){
        padding: 10px 15px 18px;
      }
    }
    .c-text{
      font-size: 1.5rem;
      color: #1A1311;
      margin-top: 25px;
      line-height: 2;
      @include mq-down(){
        margin-top: 10px;
        font-size: 1.3rem;
        line-height: 1.538;
      }
    }

    &.-col3 {
      flex: none;
      width: calc((100% - 60px) / 3);
      margin-top: 0;
      
      @include mq-down(){
        width: 100%;

        &:not(:first-child) {
          margin-top: 20px;
        }
      }

      &:nth-child(n+4) {
        margin-top: 33px;
      }

      .c-label {
        width: calc(100% + 30px);
      
        @include mq-down(){
          width: calc(100% - 35px);
        }
      }
    }
  }

  &__link {
    transition: .3s;

    &:hover{
      opacity: 1;

      .c-label {
        background: $color-red;
      }
      .c-title {
        color: $color-red;
      }
      .c-box-content-grid-type__image img {
        opacity: 0.7;
      }
    }
  }
}
