.c-list-sm{
  &__item{
    text-indent: -1em;
    padding-left: 1em;
    .c-text-common-jp.-sm{
      @include mq-down(){
        font-size: 1rem;
      }
    }
  }
}