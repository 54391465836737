.c-control {
  height: 40px;
  border: 1px solid #999;
  padding: 5px 10px;
  background-color: #fff;
  line-height: 1.2;
  font-size: 1.6rem;
  //エラー
  &.is-invalid {
    background-color: #ffe4e1;
    border-color: #e00100;
  }
  //非活性
  &[disabled] {
    opacity: .3;
  }
}
