.c-box-pdf {
  width: 100%;
  max-width: 900px;
  padding: 30px 150px;
  margin: 0 auto;
  background: #f4f4f4;

  @include mq-down() {
    padding: 20px 0;
    max-width: calc(100% - 30px);
    min-height: 70px;
  }

  &__title {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq-down{
      font-size: 1.5rem;
      line-height: 1.55;
    }
    a {
      color: #1A1311;
      left: -16px;
      position: relative;
      text-decoration: none;
      display: inline-block;
      padding-bottom: 2px;
      border-bottom: 1px solid $color-black;
      &:hover{
        color: $color-red;
        border-bottom: 1px solid $color-red;
      }
      &::after {
        content: '';
        background: url(/assets/_img/common/ico_pdf.svg) no-repeat;
        position: absolute;
        width: 22px;
        height: 29px;
        right: -32px;
        top: 50%;
        transform: translateY(-46%);
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.6;

    figure {
      max-width: 150px;
    }
  }
  &.-lg{
    padding: 30px 63px;
    @include mq-down(){
      padding: 22px 30px;
    }
    .c-box-pdf__content{
      justify-content: flex-start;
    }
    figure {
      max-width: 189px;
    }
  }
}
