.c-box-benefits{
  position: relative;
  width: calc(100% - 100px);
  margin: auto;
  @include mq-down(){
    width: 100%;
  }
  &::before{
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #A5D3EA;
    z-index: -1;
  }
  &::after{
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #F8CACA;
    z-index: -1;
  }
  &.no-bg{
    &::after, &::before{
      display: none;
    }
  }
  &.type-01 {
    &::before{
      background: #F8CACA;
    }
    &::after{
      background: #A5D3EA;
    }
  }
  &__in{
    padding-bottom: 95px;
    @include mq-down(){
      padding-bottom: 53px;
    }
    &.-reasons{
      .c-box-title{
        @include mq-down(){
          padding-top: 61px;
        }
      }
      .sub-title{
        &::before{
          left: -131px!important;
          @include mq-down(){
            left: -64px!important;
          }
        }
        &::after{
          right: -131px!important;
          @include mq-down(){
            right: -64px!important;
          }
        }
      }
    }
    .c-box-title{
      text-align: center;
      padding-top: 115px;
      @include mq-down(){
        padding-top: 53px;
      }
      &.-let-start{
        @include mq-down(){
          padding-top: 64px;
        }
        .sub-title{
          @include mq-down(){
            margin-top: -6px;
            line-height: 1.33;
          }
        }
      }
      .title{
        font-size: 9rem;
        font-weight: 700;
        text-align: center;
        font-family: $font-teko;
        line-height: 1;
        display: inline-block;
        @include mq-down(){
          font-size: 5rem;
        }
      }
      .sub-title{
        font-size: 2.8rem;
        font-weight: 700;
        text-align: center;
        line-height: 1.55;
        margin-top: -15px;
        display: inline-block;
        position: relative;
        @include mq-down(){
          font-size: 1.5rem;
          margin-top: -20px;
        }
        &.-let-start{
          &::before{
            left: -60px;
            top: -55px;
            @include mq-up(){
              left: -163px;
              top: unset;
            }
          }
          &::after{
            right: -60px;
            top: -55px;
            @include mq-up(){
              right: -163px;
              top: unset;
            }
          }
        }
        &.-interview{
          &::before{
            left: -131px;
            @include mq-up(){

            }
          }
          &::after{
            right: -131px;
            @include mq-up(){

            }
          }
        }
        &.-access{
          &::before{
            left: -131px;
            @include mq-down(){
              left: -56px;
            }
          }
          &::after{
            right: -131px;
            @include mq-down(){
              right: -56px;
            }
          }
        }
        &::before{
          content: '';
          width: 91px;
          height: 125px;
          display: block;
          background: url(/assets/_img/campaign/accent01.svg) no-repeat;
          position: absolute;
          bottom: 0;
          left: -156px;
          @include mq-down(){
            width: 52px;
            height: 72px;
            background-size: contain;
            left: -50px;
            bottom: 4px;
          }
        }
        &::after{
          content: '';
          width: 91px;
          height: 125px;
          display: block;
          background: url(/assets/_img/campaign/accent02.svg) no-repeat;
          position: absolute;
          bottom: 0;
          right: -156px;
          @include mq-down(){
            width: 52px;
            height: 72px;
            background-size: contain;
            right: -50px;
            bottom: 4px;
          }
        }
      }
    }
  }
  .-let-start-content{
    text-align: center;
    @include mq-down(){
      margin-top: 32px;
      font-size: 1.3rem;
      line-height: 1.55;
      text-align: left;
    }
    .c-text-common-jp{
      @include mq-down(){
        font-size: 1.3rem;
        text-align: left;
      }
    }
  }
}