
.c-list-footer-menu{
  display: flex;
  flex-wrap: wrap;
  padding: 0 2% 0 9.62%;
  @media screen and (min-width: 897px) and (max-width: 1060px){
    padding: 0 2% 0 5.62%;
  }
  @include mq-down(){
    width: 73.04%;
    margin-left: 10.72%;
    padding-left: 0;
    padding-right: 0;
    margin-top: -20px;
  }
  &__item{
    @include mq-down(){
      width: 50%;
      margin-top: 20px;
    }
    & + &{
      margin-left: 8.5%;
      @media screen and (min-width: 897px) and (max-width: 1060px){
        margin-left: 5.5%;
      }
      @include mq-down(){
        margin-left: 0;
      }
    }
    &.-no-margin{
      margin-left: 0;
    }
    &.-link-merit{
      width: 22.2%;
      margin-top: 35px;
      @include mq-down(){
        width: 50%;
        margin-top: 24px
      }
    }
    &.-link-beginner{
      width: 50%;
      margin-left: 14px;
      margin-top: 35px;
      @include mq-down(){
        width: 50%;
        margin-top: 24px;
        margin-left: 0;
      }
    }
    &.-link-price{
      width: 100%;
      margin-top: 35px;
      @include mq-down(){
        width: 50%;
        margin-top: 20px;
      }
    }
    &:nth-child(even){
      @include mq-down(){
        padding-left: 10px;
      }
    }
    &:nth-child(1){
      @include mq-down(){
        order: 1;
      }
    }
    &:nth-child(2){
      @include mq-down(){
        order: 2;
      }
    }
    &:nth-child(7){
      @include mq-down(){
        order: 3;
      }
    }
    &:nth-child(6){
      @include mq-down(){
        order: 4;
      }
    }
    &:nth-child(8){
      @include mq-down(){
        order: 5;
        padding-left: 0;
      }
    }
    &:nth-child(3){
      @include mq-down(){
        order: 6;
        padding-left: 10px;
      }
    }
    &:nth-child(4){
      @include mq-down(){
        order: 7;
        padding-left: 0;
      }
    }
    &:nth-child(5){
      @include mq-down(){
        order: 8;
        padding-left: 10px;
      }
    }
  }
  &__link{
    color: $color-white;
    font-family: $font-teko;
    font-size: 2rem;
    display: block;
    @include mq-down(){
      font-size: 2.8rem;
    }
    .c-text-en{
      font-weight: 700;
      }
    .c-text-jp{
      display: block;
      font-family: $font-style;
      font-size: 1.5rem;
      line-height: 1.46;
      @include mq-down(){
        font-size: 1rem;
      }
    }
  }
}