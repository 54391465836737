.c-video-modal {
  width: 100vw;
  height: 100vh;
  background: rgba(26, 19, 17, 0.5);
  position: fixed;
  top: 0;
  z-index: 21;
  display: none;

  &__in {
    background: #eaf9ff;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px 25px;

    @include mq-down() {
      width: calc(100% - 30px);
      padding: 10px;
    }
  }

  &__content {
    .iframe-container {
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;

      iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &__foot {
    text-align: center;
    font-family: $font-teko;
    font-size: 28px;
    font-weight: bold;
    margin-top: 50px;
    @include mq-down() {
      font-size: 20px;
      margin-top: 30px;
    }

    a {
      color: #1a1311;
      position: relative;
    }
  }

  &__close-icon {
    position: absolute;
    display: inline-block;
    width: 25px;
    height: 25px;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    @include mq-down() {
      width: 20px;
      height: 20px;
      top: -20px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #1a1311;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}
.modaal-video-wrap{
  max-width: 1100px;
  border-top: 25px solid #EAF9FF;
  border-left: 25px solid #EAF9FF;
  border-right: 25px solid #EAF9FF;
  border-bottom: 110px solid #EAF9FF;
  margin: 0 auto;
  @include mq-down(){
    border-top: 10px solid #EAF9FF;
    border-left: 10px solid #EAF9FF;
    border-right: 10px solid #EAF9FF;
    border-bottom: 60px solid #EAF9FF;
  }
}
.modaal-video-container{
  // padding: 25px 25px 100px;
  // background: #EAF9FF;
  // max-width: 1100px;
  iframe{
    // width: calc(100% - 50px);
    // height: calc(100% - 130px);
    // top: 25px;
    // left: 25px;
  }
}
.modaal-close{
  position: relative;
  top: -75px;
  width: auto;
  right: 0;
  @include mq-down(){
    top: -50px;
  }
  span{
    position: relative!important;
    font-family: $font-teko;
    font-size: 2.8rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $color-black;
    width: auto!important;
    height: auto!important;
    @include mq-down(){
      font-size: 2rem;
    }
  }
}
.modaal-close:before, .modaal-close:after{
  top: -16px;
  left: 29px;
  width: 2px;
  background: $color-black;
  @include mq-down(){
    width: 1px;
    top: -7px;
    left: 21px;
  }
}
.modaal-close:focus, .modaal-close:hover{
  background: transparent;
}
.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after{
  background: $color-red;
}