.c-heading-md{
  font-size: 2.4rem;
  line-height: 1.333;
  @include mq-down(){
    font-size: 1.5rem;
    margin-top: 0;
  }
  &.jp{
    font-family: $font-style;
  }
  &.en{
    font-family: $font-teko;
  }
}