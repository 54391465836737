.p-inview {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity $transition, transform $transition;
  //アクティブ時
  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
}
