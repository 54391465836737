
.c-list-other-shop{
  padding-top: 27px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mq-down(){
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    width: 69.20%;
    padding-top: 3px;
  }
  &__item{
    display: block;
    background: $color-white;
    width: 19.23%;
    flex: 0 1 19.23%;
    border: 1px solid $color-black;
    border-radius: 110px;
    text-align: center;
    @include mq-down(){
      background: transparent;
      color: $color-white;
      border: 0;
      text-align: left;
      margin-top: 20px;
    }
    & + &{
      //margin-left: 0.95%;
      @include mq-down(){
        margin-left: 0;
      }
    }
    &:nth-child(6){
      margin-left: 0;
    }
    &:nth-child(odd){
      @include mq-down(){
        width: 63.13%;
        flex: 0 0 63.13%;
      }
    }
    &:nth-child(even){
      @include mq-down(){
        width: 36.86%;
        flex: 0 0 36.86%;
      }
    }
    &:nth-last-child(-n+5){
      margin-top: 10px;
      @include mq-down(){
        margin-top: 20px;
      }
    }
  }
  &__link{
    color: $color-black;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 20px 20px!important;
    display: block;
    line-height: 1.2;
    @include mq-down(){
      color: $color-white!important;
      font-weight: normal;
      font-size: 1.3rem;
      padding: 0!important;
      line-height: 1.4;
      display: inline-block;
    }
    &::after{
      @include mq-down(){
        position: absolute!important;
        right: -17px!important;
        top: 3px!important;
      }
    }
  }
}

