
.is-video{
  position: relative;
  &::after{
    content: '';
    background: url(/assets/_img/common/ico_play.svg) no-repeat;
    background-size: 78px;
    width: 78px;
    height: 78px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}
