.c-box-content-row-type{
  display: flex;
  justify-content: space-between;
  position: relative;

  @include mq-down(){
    flex-wrap: wrap;
  }
  &.is-bg{
    @include mq-down(){
      position: relative;
    }
    .c-image{
      @include mq-down(){
        margin-bottom: 20px;
      }
      &::after{
        display: none;
        content: '';
        width: 1152px;
        height: 88.81%;
        position: absolute;
        left: -94.911%;
        bottom: -37%;
        background: #EAF9FF;
        z-index: 1;
        @include mq-down(){
          display: none;
        }
      }
    }
    .c-box-content-row-type__detail{
      padding-bottom: 80px;
      @include mq-down(){
        padding-bottom: 40px;
      }
      &::after{
        content: '';
        width: 1152px;
        height: calc(100% - 200px);
        position: absolute;
        left: 46.13%;
        bottom: 0;
        background: #EAF9FF;
        z-index: -2;
        @include mq-down(){
          content: '';
          width: calc(100% - 50px);
          height: calc(100% - 103px);
          position: absolute;
          right: -15px;
          left: unset;
          bottom: 0;
          background: #EAF9FF;
          z-index: -1;
        }
      }
    }
  }
  &:not(:last-child){
    padding-bottom: 80px;
  }
  &__detail{
    width: 57.81%;
    flex: 1 0 auto;
    position: relative;
    z-index: 2;
    padding-top: 10px;
    @include mq-down(){
      width: 100%;
      flex: 0 1 100%;
      padding-top: 0;
    }
    .c-title{
      font-size: 4.6rem;
      padding-bottom: 39px;
      @include mq-down(){
        font-size: 2.6rem;
        text-align: center;
        padding-bottom: 34px;
      }
      .c-sub-title{
        display: block;
        font-size: 2.8rem;
        font-family: $font-teko;
        margin-top: 20px;
        @include mq-down(){
          font-size: 2rem;
          margin-top: 10px;
        }
      }
    }
    .c-text{
      line-height: 1.7;
      margin-top: 15px;
      + .c-text{
        margin-top: 25px;
      }
      @include mq-down(){
        margin-top: 19px;
        font-size: 1.3rem;
        line-height: 1.6;
      }
      + .c-text{
        margin-top: 20px;
      }
    }
    * {
      + .c-link{
        margin-top: 30px;
        @include mq-down(){
          text-align: center;
          margin-top: 19px;
        }
      }
    }
    .c-image{
      img{
        @include mq-down(){
          max-width: 100%;
          position: relative;
          right: 15px;
          bottom: 0;
        }
      }
    }
  }
  &__image{
    width: 100%;
    padding-left: 50px;
    position: relative;
    z-index: 2;
    @include mq-down(){
      width: 100%;
      padding-left: 0;
    }
    .c-image{
      position: absolute;
      left: 50px;
      bottom: 0;
      padding-bottom: 30px;
      @include mq-down(){
        left: 0;
      }

      > img{
        max-width: 39vw;
        position: relative;
        z-index: 2;
        @include mq-down(){
          max-width: 100%;
        }
      }
    }
  }
  &.is-left{
    .c-box-content-row-type{
      &__image{
        order: 1;
        padding-left: 0;

        .c-image{
          position: static;
          img{
            position: absolute;
            right: 50px;
          }
        }
      }
      &__detail{
        order: 2;
      }
    }
  }
}