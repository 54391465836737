.c-data-list-campaign{
  display: flex;
  flex-wrap: wrap;
  &__title{
    width: 20.5%;
    flex: 0 1 20.5%;
    border: 2px solid #fff;
    padding: 6px;
    font-size: 1.8rem;
    color: #fff;
    text-align: center;
    align-self: center;
    @include mq-down(){
      width: auto;
      flex: 0 1 auto;
      font-size: 1.3rem;
      padding: 3px 9px;
    }
  }
  &__detail{
    width: 76.55%;
    flex: 0 1 76.55%;
    margin-left: 15px;
    padding: 2px;
    font-size: 1.8rem;
    align-self: center;
    @include mq-down(){
      width: 100%;
      flex: 0 1 100%;
      margin-left: 0;
      font-size: 1.3rem;
      line-height: 1.5;
    }
    &.-half-width{
      @include mq-down(){
        display: flex;
        flex-wrap: wrap;
      }
    }
    .en{
      font-size: 3.3rem;
      font-family: $font-teko;
      font-weight: 700;
      padding-left: 5px;
      padding-right: 5px;
      @include mq-down(){
        line-height: 1.1;
        font-size: 2.6rem;
      }
    }
    .c-text-sp{
      @include mq-down(){
        width: 125px;
        display: block;
      }
    }
    .c-text-sp-02{
      @include mq-down(){
        width: calc(100% - 125px);
        display: block;
        position: relative;
        top: 5px;
      }
    }
  }
}