.c-list-footer-sub-menu{
  &__item{
    padding-left: 20px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    &::before{
      content: 'ー';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  &__link{
    color: $color-white;
    font-size: 1.5rem;
    line-height: 1.46;
  }
  &.-two-column{
    display: flex;
    flex-wrap: wrap;
    .c-list-footer-sub-menu{
      &__item{
        width: 49.48%;
        position: relative;
        letter-spacing: -0.03em;
      }
      &__item:nth-child(even){
        width: 38.90%;
        margin-left: 25px;
      }
    }
  }
}
