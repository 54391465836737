html.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  // height: 130px;
  z-index: 10;
  background: rgba(255,255,255,1);
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ff00ff', GradientType=0 );      
  @include mq-up() {
    // height: 220px;
    position: absolute;
    top: 0;
    transform: tran slateX(-50%);
    width: 100%;
  }
  &.js-sticky{
    transition: 0.5s;
    backface-visibility: hidden;
    @include mq-up(){
      -webkit-transform: translateZ(0);
    }
  }
  &.js-sticky.is-active{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: unset;
    transition: 0.3s;
    // height: 157px;

    //overflow: hidden;
    .l-header__in{
      backface-visibility: hidden;
      padding-bottom: 40px;
      transition: .5s;
      @include mq-down(){
        padding-bottom: 12px;
        // background: #fff;
      }
    }
    &.is-hidden{
      transition: 0.5s;
      transform: translateY(-100%);
      overflow: hidden;
      .l-block{
        @include mq-down(){
          padding: 0;
        }
      }
      .l-header__logo-link{
        @include mq-down(){
          transform: translateY(-100%);
        }
      }
      .l-header__in{
        @include mq-down(){
          transform: translateY(-100%);
        }
      }
    }
    &.is-bg-white{
      transition: .5s;
      background: #fff;
      @include mq-down(){
        background: none;
        overflow: hidden;
      }
    }
  }
  &__in {
    //position: fixed;
    backface-visibility: hidden;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 12px;
    padding-bottom: 60px;
    background: rgba(255,255,255,1);
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ff00ff', GradientType=0 );
    @include mq-up() {
      position: static;
      display: flex;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 103px;
      background: none;
      justify-content: space-between;
    }
  }
  &__logo {
    position: relative;
    z-index: 10;
    transition: .5s;
    display: block;
    opacity: 1;
    &-link{
      width: 131px;
      display: block;
      &:hover {
        opacity: 1;
      }
      @include mq-up() {
        width: 197px;
      }
    }
    &-image{
      max-width: 100%;
      display: block;
      width: 197px;
      height: 46px;

      &:hover {
        opacity: 1;
      }
      @include mq-down(){
        width: 131px;
        height: 30px;
      }
    }
    &-text{
      font-size: 1.3rem;
      line-height: 1.1;
      color: #fff;
      width: 100%;
      background: #32C7D3;
      padding: 5px 15px;
      border-radius: 70px;
      display: block;
      text-align: center;
      font-weight: normal;
      font-family: $font-style;
      transition: 0.3s;
      margin-top: 5px;
      @include mq-up() {
        font-size: 1.5rem;
        padding: 5px 15px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  &__utility {
    position: fixed;
    backface-visibility: hidden;
    left: 0;
    top: 0;
    width: 100%;
    @include mq-up() {
      display: block;
      position: relative;
      margin-left: auto;
    }
  }
  &__utility-in {
    padding: 60px 10px 0;
    width: 100%;
    @include mq-up() {
      padding: 0 0 0 14.46%;
    }
    &::before{
      content: '';
      width: 100%;
      height: 100vh;
      background: #1A1311;
      opacity: 0.5;
      display: block;
      top: 0;
      left: 0;
      z-index: 1;
      position: absolute;
      @include mq-up() {
        display: none;
      }
    }
  }
  &__search {
    display: flex;
  }
  &__search-input {
    flex: 1 1 auto;
    width: 1%;
    height: 34px;
    padding: 5px 10px;
    font-size: 1.6rem;
    border-radius: 3px 0 0 3px;
  }
  &__search-trigger {
    display: flex;
  }
  &__search-button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    background-color: #248b9a;
    border-radius: 0 3px 3px 0;
    font-size: 1.2rem;
    color: #fff;
  }
  &__menu {
    overflow-y: auto;
    background: #fff;
    position: relative;
    z-index: 10;
    @include mq-down() {
      top: 0;
      left: 21.07%;
      width: calc(100% - 21.07%);
      margin: 0;
      position: absolute;
      padding: 100px 15px 96px;
      height: 100vh;
      opacity: 0.95;
    }
    @include mq-up() {
      width: 100%;
      margin: 0;
      overflow: visible;
      background: transparent;
    }
  }
  &__menu-sidebar-fix{
    &.top{
      right: -100%;
      transition: .5s;
      &.is-active{
        right: 0;
      }
    }
    @include mq-down() {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @include mq-up(){
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 20;
    }
    &:not(.u-dn-md--down){
      .l-header__menu-link{
        padding: 23px 0;
      }
    }
    .l-header__menu-item{
      width: 48.87%;
      flex: 0 1 48.87%; 
      border-radius: 10px;
      &:nth-child(1){
        display: block;
      }
      @include mq-up{
        width: 140px;
        flex: 0 1 140px;
        border-radius: 55px 0 0 55px;

        &.c-button-school{
          @include mq-up(){
            margin-top: 30px !important;
          }
        }
      }
      &:not(:first-of-type){
        @include mq-up(){
          margin-top: 11px;
        }
      }
      &:nth-last-child(-n+2){
        margin-top: 5px;
        @include mq-up(){
          margin-top: 10px;
        }
      }
      .c-text{
        line-height: 1.3;
        width: 100%;
        display: block;
        margin-top: 5px;
        @include mq-up(){
          font-size: 1.8rem;
          font-family: $font-style;
          font-weight: bold;
          line-height: 1.1;
        }
        .-small{
          @include mq-up(){
            font-size: 1.5rem;
          }
        }
      }
      &.-c-red{
        background: #DA003E;
        transition: 0.3s;
        .c-text{
          color: #fff;
        }
        &:hover{
          background: #BE093C;
          opacity: 1;
        }
      }
      &.-c-black{
        background: #1A1311;
        transition: 0.3s;
        .c-text{
          color: #fff;
        }
        &:hover{
          background: #000;
          opacity: 1;
        }
      }
      &.-c-yellow{
        background: #FFE839;
        transition: 0.3s;
        .c-title{
          color: #1A1311;
          border-color: #1A1311;
        }
        .c-text{
          color: #1A1311;
        }
        &:hover{
          background: #E8D22D;
          opacity: 1;
        }
      }
      &.-c-white{
        background: #FAFAFA;
        transition: 0.3s;
        border: 1px solid #1A1311;
        color: #1A1311;
        &:hover{
          background: #FAFAFA;
          opacity: 1;
        }
      }
      + .l-header__menu-item{
        @include mq-up(){
          padding-left: 0;
        }
      }
    }
    .l-header__menu-link{
      font-size: 1.5rem;
      text-align: center;
      transition: 0.3s;
      @include mq-up(){
        padding-left: 20px;
        padding-top: 19px;
        padding-bottom: 23px;
      }
      .c-title{
        @include mq-down() {
          display: none;
        }
        @include mq-up() {
          display: inline-block;
          color: #fff;
          border-bottom: 2px solid #fff;
          font-size: 2rem;
        }
      }
      &:hover{
        opacity: 1;
      }
      &.c-button-school{
        @include mq-up(){
          margin-top: 32px;
        }
      }
    }
  }
  &__menu-list-footer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 20px 15px 50px;
    margin-top: 23px;
    @include mq-up(){
      display: none;
    }
    &::before{
      content: '';
      display: block;
      width: 37.6%;
      height: 2px;
      position: absolute;
      top: 0;
      left: 15px;
      background: #000;
    }
    .l-header{
      &__menu-item{
        width: 50%;
        flex: 0 1 50%;
        line-height: 1;
        &:nth-last-child(-n+2){
          margin-top: 5px;
        }
        &:nth-child(even){
          padding-left: 24px;
        }
      }
      &__menu-link{
        font-size: 1rem;
        color: #1A1311;
        padding: 3px 0 2px!important;
        &[target="_blank"]:not(.no-icon){
          &::after{
            content: '';
            background: url(/assets/_img/common/ico_link.svg) no-repeat;
            width: 12px;
            height: 12px;
            display: inline-block;
            margin-left: 4px;
          }
        }
      }
    }

  }
  &__menu-list {
    @include mq-down() {
      margin-top: 30px;
      padding: 0 15px;
    }
    @include mq-up() {
      display: flex;
    }
    .l-header__menu-item{
      + .l-header__menu-item{
        @include mq-down() {
          margin-top: 10px;
        }
      }
    }
  }
  &__menu-item {
    @include mq-up() {
      display: flex;
      flex: 0 1 auto; 
    }
    & + & {
      @include mq-up() {
        padding-left: 5.5%;
        border-top: none;
      }
    }
    &:nth-child(1){
      display: block;
      @include mq-up() {
        display: none;
      }
    }
    &:nth-child(2){
      @include mq-up() {
        padding-left: 0;
      }
    }
  }
  &__menu-link {
    display: block;
    padding: 10px 0;
    color: #1A1311;
    font-family: $font-teko;
    font-size: 2.8rem;
    font-weight: 600;
    text-transform: uppercase;
    @include mq-down(){
      padding: 6px 0;
    }
    @include mq-up() {
      text-align: center;
      width: 100%;
      transition: 0.3s;
    }
    &:hover{
      color: $first-letter-en;
    }
  }
  &__submenu {
    background-color: #136e7b;
    @include mq-up() {
      display: none;
      position: absolute;
      width: 100vw;
      top: 100%;
      left: -20px;
      padding: 40px calc((100% - 1000px) / 2 + 20px);
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      background-color: #333;
    }
  }
  &__submenu-list {
    @include mq-up() {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      margin-top: -10px;
    }
  }
  &__submenu-item {
    @include mq-up() {
      margin-left: 20px;
      margin-top: 10px;
    }
  }
  &__submenu-link {
    display: block;
    border-top: 1px solid #248b9a;
    padding: 20px;
    line-height: 1.5;
    font-size: 1.2rem;
    color: #fff;
    @include mq-up() {
      border: none;
      padding: 0;
      color: #fff;
    }
  }
  &__menu-tel{
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 80px;
    width: 60px;
    height: 60px;
    background: #32C7D3;
    border-radius: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq-up() {
      display: none;
    }
  }
  &__menu-trigger,
  &__menu-trigger::before,
  &__menu-trigger::after,
  &__menu-trigger-mark {
    display: inline-block;
    transition: transform $transition, opacity $transition;
  }
  &__menu-trigger {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    border: 1px solid #32C7D3;
    background: #fff;
    border-radius: 90px;
    z-index: 11;
    @include mq-up() {
      display: none;
    }
  }
  &__menu-trigger::before,
  &__menu-trigger::after,
  &__menu-trigger-mark {
    position: absolute;
    left: 15px;
    width: calc(100% - 30px);
    height: 2px;
    background-color: #32C7D3;
    border-radius: 2px;
  }
  &__menu-trigger::before,
  &__menu-trigger::after {
    content: '';
  }
  &__menu-trigger::before {
    top: 18px;
  }
  &__menu-trigger::after {
    bottom: 18px;
  }
  &__menu-trigger-mark {
    top: 28px; 
  }
  // 上からスライド
  .is-loaded &__utility--top {
    @include mq-down() {
      transition: max-height $transition;
    }
  }
  &__utility--top {
    @include mq-down() {
      max-height: 0;
      overflow: hidden;
    }
  }
  // 右からスライドフェード
  .is-loaded &__utility--right {
    @include mq-down() {
      transition: opacity $transition, transform $transition;
    }
  }
  &__utility--right {
    @include mq-down() {
      opacity: 0;
      transform: translateX(100%);
    }
  }
  // フェードイン
  .is-loaded &__utility--fade {
    @include mq-down() {
      transition: opacity $transition;
    }
  }
  &__utility--fade {
    @include mq-down() {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  // メニューアクティブ
  &__menu-trigger.is-active + &__utility--top {
    @include mq-down() {
      max-height: 100vh;
    }
  }
  &__menu-trigger.is-active + &__utility--right {
    @include mq-down() {
      transform: translate(0);
      opacity: 1;
      width: 100%;
      z-index: 10;
      padding-top: 100px;
    }
  }
  &__menu-trigger.is-active + &__utility--fade {
    @include mq-down() {
      transform: none;
      opacity: 1;
    }
  }
  &__menu-trigger.is-active::before {
    transform: translateY(10px) rotate(-45deg);
  }
  &__menu-trigger.is-active &__menu-trigger-mark {
    opacity: 0;
  }
  &__menu-trigger.is-active::after {
    transform: translateY(-10px) rotate(45deg);
  }
  // サブメニューアクティブ SP
  &__submenu {
    @include mq-down() {
      @include hidden();
    }
  }
  // サブメニューアクティブ PC
  &__menu-link.is-active {
  }
  &__menu-link.is-active + &__submenu {
    @include mq-up() {
      display: block;
      max-height: none !important;
    }
  }
  // メニュー追従
  &__menu.is-active {
    @include mq-up() {
      position: fixed;
      top: 0;
      bottom: auto;
    }
  }
}
