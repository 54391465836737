/* ==========================================================================//
//
// [デフォルトスタイル]
//
// ========================================================================== */

html, body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  line-height: 1;
  font-size: $fontsize-sp;
  font-family: $font-style;
  background: $body-background;
  color: $font-color;
  font-display: swap;
  
  @include mq-up() {
    font-size: $fontsize-pc;
  }
  @include mq-down() {
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
  }
  & > * {
    flex-shrink: 0;
  }
}

*:not(hr):not(legend),
*::before, *::after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, figure, figcaption {
  margin: 0;
}

ul, ol, dl, dt, dd, blockquote {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

a, button {
  transition: opacity $transition;
  outline: none;
  &:hover {
    @include mq-up(){
      opacity: $opacity;
    }
  }
}

a {
  color: $link-color;
  text-decoration: none;
  &[href^="tel"] {
    @include mq-up(){
      pointer-events: none;
      cursor: default;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

figure{
  img{
    @include mq-down(){
      width: 100%;
    }
  }
}

svg {
  display: inline-block;
}

picture {
  display: block;
}

i {
  font-style: normal;
}

table {
  width: 100%;
  border-spacing: 0;
}

th {
  font-weight: normal;
}

td {
  text-align: center;
}

input {
  border-radius: 0;
}

[role=button], [type=button], [type=reset], [type=submit], button {
  cursor: pointer;
}

[disabled] {
  cursor: default;
  pointer-events: none;
}

[type=number] {
  width: auto;
}

[type=search] {
  appearance: textfield;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  appearance: none;
}

textarea {
  vertical-align: top;
  overflow: auto;
  resize: vertical;
  border-radius: 0;
}

button, input, optgroup, select, textarea, small {
  font: inherit;
}

optgroup {
  font-weight: 700;
}

button {
  padding: 0;
  overflow: visible;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type=reset], [type=submit], button, html [type=button] {
  appearance: button;
}

button, select {
  text-transform: none;
}

button, input, select, textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}

input:not([type="checkbox"]), input:not([type="radio"]), textarea {
  appearance: none;
}

select {
  border-radius: 0;
  appearance: none;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
  background: none;
}

fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

legend {
  border: none;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

::-webkit-file-upload-button {
  appearance: button;
  font: inherit;
}

[type=search] {
  appearance: textfield;
  outline-offset: -.02rem;
}

input, textarea, select {
  display: inline-block;
  width: 100%;
  padding: 0;
  border: none;
  background: #fff;
}

textarea {
  height: auto;
  resize: none;
}

:placeholder-shown { color: $placeholder-color;}
::-webkit-input-placeholder { color: $placeholder-color;}
::-moz-placeholder { color: $placeholder-color; opacity: 1;}
:-ms-input-placeholder { color: $placeholder-color;}
