.c-list-price{
  margin-top: 24px;
  @include mq-down(){
    margin-top: 21px;
  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    @include mq-down(){
      font-size: 1.3rem;
    }
    + .c-list-price__item{
      margin-top: 10px;
    }
    .c-box{
      font-size: 1.5rem;
      line-height: 1.2;
      padding: 5px 8px;
      border: 1px solid $color-black;
      display: inline-block;
      margin-right: 15px;
      font-weight: 700;
      align-self: flex-start;
      background: #fff;
      min-width: 80px;
      text-align: center;
      @include mq-down(){
        font-size: 1.3rem;
        line-height: 1.538;
        margin-right: 6px;
        min-width: 70px;
      }
    }
    .c-text-price{
      line-height: 1.2;
      width: 85%;
      flex: 0 1 85%;
      align-self: flex-start;
      padding-top: 7px;
      @include mq-down(){
        width: 74.5%;
        flex: 0 1 74.5%;
        line-height: 1.538;
      }
    }
  }
}