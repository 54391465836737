.c-hero-detail{
  border: 6px solid #fff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1050px;
  width: 100%;
  text-align: center;
  color: #fff;
  @media only screen and (min-width: 897px) and (max-width: 1180px) {
    width: calc(100% - 30px);
  }
  @include mq-down(){
    width: calc(100% - 30px);
  }
  &__in{
    padding: 6.75% 12.05%;
    @include mq-down(){
      padding: 32px 26px 50px;
    }
    &::after{
      content: '';
      width :100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #117FB1;
      opacity: 0.6;
      display: block;
      z-index: -1;
    }
  }
  &__title{
    font-size: 2.4rem;
    color: #fff;
    font-weight: 700;
    @include mq-down(){
      font-size: 2rem;
      line-height: 1.6;
    }
  }
  &__sub-title{
    font-size: 3.2rem;
    color: #fff;
    font-weight: 700;margin-top: 10px;
    @include mq-down(){
      font-size: 2.6rem;
      line-height: 1.4;
    }
  }
  &__price{
    font-size: 14rem;
    line-height: 1.1;
    font-weight: 700;
    color: #FA7777;
    font-family: $font-teko;
    position: relative;
    top: 15px;
    display: inline-block;
    @include mq-down(){
      font-size: 11rem;
      line-height: 0.75;
      top: 19px;
    }
    .c-text-small{
      font-family: $font-style;
      font-size: 2.4rem;
      @include mq-down(){
        font-size: 2rem;
        display: block;
        width: 100%;
        text-align: right;
        position: relative;
        right: 20px;
        top: -6px;
      }
    }
  }
  &__cash-back{
    font-size: 4.6rem;
    font-weight: 700;
    text-align: center;
    color: #FA7777;
    @include mq-down(){
      font-size: 2rem;
      margin-top: 30px;
    }
  }
} 